import { getNavigatorLocale, getAppLoginFooterTranslations } from 'core/i18n';
import { LINK_DEMO } from 'core/constants';
import { Page, paths } from 'core/routes';
import type { FooterItemType } from 'modules/Login/Footer/types';

const locale = getNavigatorLocale();
const translations = getAppLoginFooterTranslations();

export function useFooter(): FooterItemType[] {
  return [
    {
      message: translations.account.message,
      link: {
        href: LINK_DEMO[locale],
        text: translations.account.link,
      },
    },
    {
      message: translations.freeTrial.message,
      link: {
        href: paths[Page.APP_SIGN_UP],
        text: translations.freeTrial.link,
      },
    },
  ];
}
