import { Selector } from 'core/constants';
import { GRAVITEE_URL } from 'core/env';
import { getInput } from 'core/helpers';

export async function resendMfaCode(
  redirectUri: string,
): Promise<{ csrfToken: string; hasError: boolean }> {
  try {
    const clientId = getInput(Selector.CLIENT_ID).value;
    const response = await fetch(
      `${GRAVITEE_URL}/am/kameleoon/mfa/challenge?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`,
      {
        method: 'GET',
      },
    );

    const parser = new DOMParser();
    const htmlText = await response.text();
    const doc = parser.parseFromString(htmlText, 'text/html');
    const csrfToken = getInput(Selector.CSRF_TOKEN, doc).value;

    return {
      csrfToken,
      hasError: response.status !== 200,
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
