import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  AppLogin,
  AppLoginSSOAuthorize,
  AppLoginSSOAuthorizeGoogle,
  AppLoginSSOError,
  AppLoginSSOErrorGoogle,
  AppRestore,
  AppResetPassword,
  AppSignUp,
  AppSignUpVerify,
  AppSignUpVerified,
  AppFreeTrialError,
  AppAdmin,
  AppAdminSSO,
  AppAdminSSOAuthorize,
  AppAdminSSOError,
  AppLoginMfaVerify,
} from 'projects/app';
import {
  AdminLogin,
  AdminSSO,
  AdminSSOAuthorize,
  AdminSSOError,
} from 'projects/admin';
import type { RouteType } from './types';
import { Page, paths } from './constants';

export function getRoutes(): RouteType[] {
  return [
    {
      type: Page.APP_LOGIN_MFA_VERIFY,
      path: paths[Page.APP_LOGIN_MFA_VERIFY],
      exact: true,
      component: <AppLoginMfaVerify />,
    },
    {
      type: Page.APP_LOGIN,
      path: paths[Page.APP_LOGIN],
      exact: true,
      component: <AppLogin type="app" />,
    },

    {
      type: Page.APP_LOGIN_SSO_AUTHORIZE,
      path: paths[Page.APP_LOGIN_SSO_AUTHORIZE],
      exact: true,
      component: <AppLoginSSOAuthorize />,
    },
    {
      type: Page.APP_LOGIN_SSO_GOOGLE_AUTHORIZE,
      path: paths[Page.APP_LOGIN_SSO_GOOGLE_AUTHORIZE],
      exact: true,
      component: <AppLoginSSOAuthorizeGoogle />,
    },
    {
      type: Page.APP_LOGIN_SSO_ERROR,
      path: paths[Page.APP_LOGIN_SSO_ERROR],
      exact: true,
      component: <AppLoginSSOError />,
    },
    {
      type: Page.APP_LOGIN_SSO_GOOGLE_ERROR,
      path: paths[Page.APP_LOGIN_SSO_GOOGLE_ERROR],
      exact: true,
      component: <AppLoginSSOErrorGoogle />,
    },
    {
      type: Page.APP_RESTORE,
      path: paths[Page.APP_RESTORE],
      exact: true,
      component: <AppRestore />,
    },
    {
      type: Page.APP_RESET_PASSWORD,
      path: paths[Page.APP_RESET_PASSWORD],
      exact: true,
      component: <AppResetPassword />,
    },
    {
      type: Page.ACADEMY_LOGIN,
      path: paths[Page.ACADEMY_LOGIN],
      exact: true,
      component: <AppLogin type="academy" />,
    },
    {
      type: Page.APP_ADMIN,
      path: paths[Page.APP_ADMIN],
      exact: true,
      component: <AppAdmin />,
    },
    {
      type: Page.APP_ADMIN_SSO,
      path: paths[Page.APP_ADMIN_SSO],
      exact: true,
      component: <AppAdminSSO />,
    },
    {
      type: Page.APP_ADMIN_SSO_AUTHORIZE,
      path: paths[Page.APP_ADMIN_SSO_AUTHORIZE],
      exact: true,
      component: <AppAdminSSOAuthorize />,
    },
    {
      type: Page.APP_ADMIN_SSO_ERROR,
      path: paths[Page.APP_ADMIN_SSO_ERROR],
      exact: true,
      component: <AppAdminSSOError />,
    },
    {
      type: Page.APP_EDITOR,
      path: paths[Page.APP_EDITOR],
      exact: true,
      component: <AppLogin type="editor" />,
    },
    {
      type: Page.APP_SIGN_UP_VERIFY,
      path: paths[Page.APP_SIGN_UP_VERIFY],
      exact: true,
      component: <AppSignUpVerify />,
    },
    {
      type: Page.APP_SIGN_UP_VERIFIED,
      path: paths[Page.APP_SIGN_UP_VERIFIED],
      exact: true,
      component: <AppSignUpVerified />,
    },
    // APP_SIGN_UP route must be after APP_SIGN_UP_VERIFY, APP_SIGN_UP_VERIFIED !
    {
      type: Page.APP_SIGN_UP,
      path: `${paths[Page.APP_SIGN_UP]}/:promocode?`,
      exact: true,
      component: (
        <GoogleReCaptchaProvider reCaptchaKey="6LeiikQlAAAAAABFKhC6EhW24oNp0UDPbOc4dNxD">
          <AppSignUp />
        </GoogleReCaptchaProvider>
      ),
    },
    {
      type: Page.APP_FREE_TRIAL_ERROR,
      path: paths[Page.APP_FREE_TRIAL_ERROR],
      exact: true,
      component: <AppFreeTrialError />,
    },
    {
      type: Page.ADMIN_LOGIN,
      path: paths[Page.ADMIN_LOGIN],
      exact: true,
      component: <AdminLogin />,
    },
    {
      type: Page.ADMIN_SSO,
      path: paths[Page.ADMIN_SSO],
      exact: true,
      component: <AdminSSO />,
    },
    {
      type: Page.ADMIN_SSO_AUTHORIZE,
      path: paths[Page.ADMIN_SSO_AUTHORIZE],
      exact: true,
      component: <AdminSSOAuthorize />,
    },
    {
      type: Page.ADMIN_SSO_ERROR,
      path: paths[Page.ADMIN_SSO_ERROR],
      exact: true,
      component: <AdminSSOError />,
    },
  ];
}
