import type { FormEvent, ReactNode } from 'react';
import {
  memo,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import type { TypeError, TypeLoginFormTranslations } from '../types';
import type { TypeUseUserHookResult, TypeUpdateUserEvent } from '../useUser';
import { User } from '../constants';
import { Form } from '../Form';
import { Container } from '../Container';
import { Title } from '../Title';
import { Subtitle } from '../Subtitle';
import { Label } from '../Label';
import { Field, Password } from '../Field';
import { Tooltip } from '../Tooltip';
import { Errors } from '../Errors';
import { ButtonSubmit } from '../ButtonSubmit';

export type LoginFormTemplateRef = {
  username: {
    focus: () => void;
  };
  password: {
    focus: () => void;
  };
};

type Props = {
  user: TypeUseUserHookResult['user'];
  error: TypeError;
  hasError: TypeUseUserHookResult['hasError'];
  loading: boolean;
  autocompleteUsername?: string;
  autocompletePassword?: string;
  addonContentTop?: ReactNode;
  addonPasswordAfter?: ReactNode;
  addonContentBottom?: ReactNode;
  addonFormTop?: ReactNode;
  addonControlsBottom?: ReactNode;
  addonFormBottom?: ReactNode;
  classNameErrors?: string;
  onChangeUser: (event: TypeUpdateUserEvent) => void;
  onSubmit: () => void;
  translations: TypeLoginFormTranslations;
};

const FormTemplate = forwardRef<LoginFormTemplateRef, Props>(
  function FormTemplate(
    {
      user,
      error,
      hasError,
      loading,
      autocompleteUsername = 'username',
      autocompletePassword,
      addonContentTop,
      addonPasswordAfter,
      addonContentBottom,
      addonFormTop,
      addonControlsBottom,
      addonFormBottom,
      classNameErrors,
      onChangeUser,
      onSubmit,
      translations,
    },
    ref,
  ): JSX.Element {
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      username: {
        focus: () => {
          usernameRef.current?.focus();
        },
      },
      password: {
        focus: () => {
          passwordRef.current?.focus();
        },
      },
    }));

    const handleSubmit = useCallback(
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit();
      },
      [onSubmit],
    );

    return (
      <Form onSubmit={handleSubmit}>
        {addonFormTop}
        <Container type="title">
          <Title>{translations.title}</Title>
        </Container>
        <Container type="subtitle">
          <Subtitle>{translations.subtitle}</Subtitle>
        </Container>
        <Container type="content">
          {addonContentTop}
          <Container type="username">
            <Label htmlFor={User.USERNAME}>{translations.label.username}</Label>
            <Field
              autocomplete={autocompleteUsername}
              loading={loading}
              hasError={hasError}
              id={User.USERNAME}
              name={User.USERNAME}
              onChange={onChangeUser}
              ref={usernameRef}
              value={user[User.USERNAME]}
            />
          </Container>
          <Container type="password">
            <Label htmlFor={User.PASSWORD}>
              {translations.label.password}
              <Container type="tooltip">
                <Tooltip
                  ariaLabel={translations.aria.password.tooltip}
                  content={translations.tooltip.password}
                />
              </Container>
            </Label>
            <Password
              autocomplete={autocompletePassword}
              aria={translations.aria.password}
              hasError={hasError}
              id={User.PASSWORD}
              loading={loading}
              name={User.PASSWORD}
              onChange={onChangeUser}
              ref={passwordRef}
              value={user[User.PASSWORD]}
            />
          </Container>
          {addonPasswordAfter}
          <Errors
            className={classNameErrors}
            position={error.position}
            messages={error.messages}
          />
          {addonContentBottom}
        </Container>
        <Container type="controls">
          <ButtonSubmit loading={loading} label={translations.button.login} />
          {addonControlsBottom}
        </Container>
        {addonFormBottom}
      </Form>
    );
  },
);

export default memo(FormTemplate);
