import { Errors } from 'core/constants';
import type { TypeError } from 'modules/Login';
import { Login } from 'modules/Login';

export type LoginErrorMessagesType = {
  loginFailed: string;
  sessionExpired: string;
  maximalPasswordAttempts: string;
  temporarilyBlocked: string;
  pleaseReset: string;
  accountDeactivated: string;
  unauthorizedIpAddress: string;
  contantAdmin: string;
};

export function getLoginErrorMessage(
  translations: LoginErrorMessagesType,
  error?: string | null,
): TypeError {
  switch (error) {
    case Errors.MISSING_FIELDS:
    case Errors.LOGIN_FAILED:
    case Errors.BAD_CREDENTIALS:
      return {
        messages: [
          {
            key: Errors.LOGIN_FAILED,
            value: translations.loginFailed,
          },
        ],
      };
    case Errors.SESSION_EXPIRED:
      return {
        messages: [
          {
            key: Errors.SESSION_EXPIRED,
            value: translations.sessionExpired,
          },
        ],
      };
    case Errors.MAX_PASSWORD_ATTEMPTS:
      return {
        position: Login.ErrorPosition.Center,
        messages: [
          {
            key: `${Errors.SESSION_EXPIRED}-1`,
            value: translations.maximalPasswordAttempts,
          },
          {
            key: `${Errors.SESSION_EXPIRED}-2`,
            value: translations.temporarilyBlocked,
          },
          {
            key: `${Errors.SESSION_EXPIRED}-3`,
            value: translations.pleaseReset,
          },
        ],
      };
    case Errors.UNAUTHORIZED_IP_ADDRESS:
      return {
        position: Login.ErrorPosition.Center,
        messages: [
          {
            key: `${Errors.UNAUTHORIZED_IP_ADDRESS}-1`,
            value: translations.unauthorizedIpAddress,
          },
          {
            key: `${Errors.UNAUTHORIZED_IP_ADDRESS}-2`,
            value: translations.contantAdmin,
          },
        ],
      };
    case Errors.ACCOUNT_DEACTIVATED:
      return {
        messages: [
          {
            key: Errors.ACCOUNT_DEACTIVATED,
            value: translations.accountDeactivated,
          },
        ],
      };
    default:
      return {
        messages: [],
      };
  }
}
