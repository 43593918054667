import { useCallback, useState } from 'react';
import type { SignUpParams } from './signUp';
import { signUp } from './signUp';

type TypeLoginHookResult = {
  loading: boolean;
  createAccount: (params: SignUpParams) => Promise<boolean>;
};

export function useSignUp(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const createAccount = useCallback(async (params: SignUpParams) => {
    setLoading(true);

    try {
      const success = await signUp(params);

      return success;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    createAccount,
  };
}
