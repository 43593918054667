export enum Errors {
  // Frontend errors
  MISSING_FIELDS = 'MISSING_FIELDS',
  NO_MATCH = 'NO_MATCH',
  PASSWORD_REGEXP = 'PASSWORD_REGEXP',

  // Rest response errors
  UNDEFINED_ERROR = 'UNDEFINED_ERROR',
  MAX_PASSWORD_ATTEMPTS = 'MAX_PASSWORD_ATTEMPTS',
  UNAUTHORIZED_IP_ADDRESS = 'UNAUTHORIZED_IP_ADDRESS',
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  ACCOUNT_DEACTIVATED = 'ACCOUNT_DEACTIVATED',
  FREE_TRIAL_ENDED = 'FREE_TRIAL_ENDED',
  FREE_TRIAL_INACTIVE_ACCOUNT = 'FREE_TRIAL_INACTIVE_ACCOUNT',

  // Rest status errors
  USER_NOT_FOUND = 'USER_NOT_FOUND', // 410
  TOO_MANY_ATTEMPTS = 'TOO_MANY_ATTEMPTS', // 429
  UNEXPECTED = 'UNEXPECTED', // 500
  NOT_YET_ACTIVE = 'NOT_YET_ACTIVE', // 401

  // Gravitee errors
  LOGIN_FAILED = 'login_failed',
  SESSION_EXPIRED = 'session_expired',

  // Mfa errors
  INVALID_CODE = 'invalid_code',
  SERVER_ERROR = 'server_error',
}
