import { useCallback, useEffect } from 'react';
import { useSearchParamValue } from 'core/routes';
import { getInput } from 'core/helpers';
import { SearchParam, Selector } from 'core/constants';
import { applyTokens } from 'core/api';
import { getAuthSSOError } from './getAuthSSOError';

type Params = {
  redirectUri: string;
  rememberMe: boolean;
  callback: (params: { hasAuthSSOError: boolean }) => void;
  catchCallback: () => void;
};

export function useSSOAuthorize({
  redirectUri,
  rememberMe,
  callback,
  catchCallback,
}: Params): void {
  const clientId = getInput(Selector.CLIENT_ID).value;
  const code = useSearchParamValue(SearchParam.CODE);

  const login = useCallback(async () => {
    try {
      if (code) {
        const applied = await applyTokens({
          clientId,
          code,
          rememberMe,
          redirectUri,
        });

        if (applied) {
          const hasAuthSSOError = await getAuthSSOError();

          callback({ hasAuthSSOError });
        } else {
          throw new Error('Tokens has not been applied!');
        }
      } else {
        throw new Error('The code parameter is not defined!');
      }
    } catch {
      catchCallback();
    }
  }, [code, clientId, rememberMe, redirectUri, callback, catchCallback]);

  useEffect(() => {
    login();
  }, [login]);
}
