export enum SearchParam {
  CODE = 'code',
  ERROR = 'error',
  TOKEN = 'token',
  ACCOUNT_ID = 'accountId',
  REDIRECT = 'redirect',
  LOGIN_TYPE = 'loginType',
  IMPERSONATE_ACCOUNT = 'impersonateAccount',
  FACTOR_ID = 'factorId',
  EMAIL = 'email',
}
