import { memo } from 'react';
import { getNavigatorLocale, getTermsOfServiceTranslations } from 'core/i18n';
import { LINK_TERMS_SERVICE, LINK_PRIVACY_POLICY } from 'core/constants';
import { LegalLinks } from 'modules/LegalLinks';

const locale = getNavigatorLocale();
const translations = getTermsOfServiceTranslations();

const items = [
  {
    href: LINK_TERMS_SERVICE[locale],
    text: translations.termService,
  },
  {
    href: LINK_PRIVACY_POLICY[locale],
    text: translations.privacyPolicy,
  },
];

function TermsOfService(): JSX.Element {
  return <LegalLinks items={items} />;
}

export default memo(TermsOfService);
