import { useCallback, useEffect, useRef, memo } from 'react';
import { Errors, SearchParam } from 'core/constants';
import { getAppAdminTranslations, getLoginErrorMessage } from 'core/i18n';
import { useLogin } from 'core/api';
import { APP_REDIRECT_URL, HAS_DEPLOY_INFO } from 'core/env';
import { Page, useRedirectAfterLogin, useSearchParamValue } from 'core/routes';
import { Login } from 'modules/Login';
import { User } from 'modules/Login/constants';
import { Title } from 'modules/Title';
import backgroundUrl from 'assets/images/app-background.svg';

const translations = getAppAdminTranslations();
const redirectUri = APP_REDIRECT_URL;

function AppAdmin(): JSX.Element {
  const redirectAfterLogin = useRedirectAfterLogin({
    page: Page.APP_ADMIN,
    redirectUri,
  });
  const { loading, login } = useLogin({
    redirectUri,
    callback: () => {
      window.location.href = redirectAfterLogin;
    },
  });
  const customerRef = useRef<HTMLInputElement>(null);
  const impersonateAccount =
    useSearchParamValue(SearchParam.IMPERSONATE_ACCOUNT) || '';
  const {
    user,
    error,
    hasError,
    setUser: handleChangeUser,
    setError,
  } = Login.useUser({ [User.CUSTOMER]: impersonateAccount });

  useEffect(() => {
    customerRef.current?.focus();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (user.customer === '' || user.username === '' || user.password === '') {
      setError(Errors.MISSING_FIELDS);
      customerRef.current?.focus();
      return;
    }

    const { error: loginError } = await login({
      username: `${String(user.username).trim()}_IMPERSONATE_${String(
        user.customer,
      ).trim()}`,
      password: user.password,
      rememberMe: true,
    });

    if (loginError) {
      setError(loginError);
    }
  }, [login, setError, user.customer, user.password, user.username]);

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
      addonFooter={
        HAS_DEPLOY_INFO && (
          <Login.DeployInfo url={`${APP_REDIRECT_URL}/deploy_info.html`} />
        )
      }
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.FormTemplate
        addonContentTop={
          <Login.Container type="username">
            <Login.Label htmlFor={User.CUSTOMER}>
              {translations.label.customer}
            </Login.Label>
            <Login.Field
              autocomplete="off"
              id={User.CUSTOMER}
              hasError={hasError}
              loading={loading}
              name={User.CUSTOMER}
              onChange={handleChangeUser}
              ref={customerRef}
              value={user[User.CUSTOMER]}
            />
          </Login.Container>
        }
        user={user}
        error={getLoginErrorMessage(translations.error, error)}
        loading={loading}
        onChangeUser={handleChangeUser}
        onSubmit={handleSubmit}
        hasError={hasError}
        translations={translations}
      />
    </Login.Page>
  );
}

export default memo(AppAdmin);
