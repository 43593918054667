import { useCallback, useState } from 'react';
import { getInput } from 'core/helpers';
import { Errors, Selector } from 'core/constants';
import { APP_REDIRECT_URL, GRAVITEE_URL } from 'core/env';
import { Page, paths } from 'core/routes';
import { loginMfa } from './loginMfa';
import { applyTokens } from './applyTokens';

type Params = {
  code: string;
  factorId: string;
  callback: () => void;
};

type TypeLoginHookResult = {
  loading: boolean;
  verifiedCode: (params: Params) => Promise<{
    code: string | null;
    error: Errors | null;
  }>;
};

export function useLoginMfa(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);
  const redirectUri = APP_REDIRECT_URL;

  const verifiedCode = useCallback(
    async ({ callback, ...params }) => {
      setLoading(true);

      try {
        const response = await loginMfa(params, redirectUri);

        if (response.error === Errors.SERVER_ERROR) {
          window.location.href = `${GRAVITEE_URL}${paths[Page.APP_LOGIN]}`;

          return response;
        }

        if (response.csrfToken) {
          getInput(Selector.CSRF_TOKEN).value = response.csrfToken;
        }

        if (response.code) {
          const applied = await applyTokens({
            clientId: getInput(Selector.CLIENT_ID).value,
            code: response.code,
            rememberMe: true,
            redirectUri,
          });

          if (applied) {
            callback();
          } else {
            throw new Error('Tokens were not applied!');
          }
        }

        return response;
      } catch (error) {
        throw new Error(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    },
    [redirectUri],
  );

  return {
    loading,
    verifiedCode,
  };
}
