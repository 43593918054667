import en from 'locales/app.en.json';
import fr from 'locales/app.fr.json';
import de from 'locales/app.de.json';
import { Locale } from 'core/constants';

export function getAppLocales(locale: Locale): typeof en {
  switch (locale) {
    default:
    case Locale.EN:
      return en;
    case Locale.FR:
      return fr;
    case Locale.DE:
      return de;
  }
}
