import { REST_URL } from 'core/env';

type Params = {
  clientId: string;
  code: string;
  rememberMe: boolean;
  redirectUri: string;
};

export async function applyTokens(params: Params): Promise<boolean> {
  try {
    await fetch(`${REST_URL}/auth-proxy/tokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(params),
    });

    return true;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
