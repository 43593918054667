import type { LoginFormTemplateRef } from 'modules/Login';
import { useCallback, useEffect, useRef, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@kameleoon/ui';
import {
  getAppLoginTranslations,
  getLoginErrorMessage,
  getAcademyLoginTranslations,
} from 'core/i18n';
import { APP_REDIRECT_URL } from 'core/env';
import { Errors, SearchParam } from 'core/constants';
import { useLogin, useAcademyLogin } from 'core/api';
import {
  Page,
  paths,
  useRedirectAfterLogin,
  useSearchParamValue,
} from 'core/routes';
import { Login } from 'modules/Login';
import { User } from 'modules/Login/constants';
import { Title } from 'modules/Title';
import backgroundUrl from 'assets/images/app-background.svg';
import { GoogleSSO } from './GoogleSSO';
import { useFooter } from './useFooter';
import styles from './AppLogin.module.scss';

const redirectUri = APP_REDIRECT_URL;

const LoginType = { App: 'app', Editor: 'editor', Academy: 'academy' } as const;

type Props = {
  type: typeof LoginType[keyof typeof LoginType];
};

function AppLogin({ type }: Props): JSX.Element {
  const { redirectToAcademy } = useAcademyLogin();
  const translations =
    type === LoginType.Academy
      ? getAcademyLoginTranslations()
      : getAppLoginTranslations();
  const { push } = useHistory();
  const redirectToAppAfterLogin = useRedirectAfterLogin({
    page: Page.APP_LOGIN,
    redirectUri,
  });
  const redirectToEditorAfterLogin = useSearchParamValue(SearchParam.REDIRECT);
  const { loading, login } = useLogin({
    redirectUri,
    callback: () => {
      if (type === LoginType.Editor && redirectToEditorAfterLogin) {
        window.location.href = `${redirectToEditorAfterLogin}${window.location.hash}`;
        return;
      }

      if (type === LoginType.Academy) {
        redirectToAcademy();
        return;
      }

      window.location.href = redirectToAppAfterLogin;
    },
  });
  const usernameRef = useRef<LoginFormTemplateRef>(null);
  const {
    user,
    error,
    hasError,
    setUser: handleChangeUser,
    setError,
  } = Login.useUser();

  useEffect(() => {
    usernameRef.current?.username.focus();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (user.username === '' || user.password === '') {
      setError(Errors.MISSING_FIELDS);
      usernameRef.current?.username.focus();
      return;
    }

    const { error: loginError } = await login(user);

    if (loginError) {
      if (loginError === Errors.FREE_TRIAL_ENDED) {
        push(
          `${paths[Page.APP_FREE_TRIAL_ERROR]}?email=${encodeURIComponent(
            user.username,
          )}`,
        );
        return;
      }

      if (loginError === Errors.FREE_TRIAL_INACTIVE_ACCOUNT) {
        push(
          `${paths[Page.APP_SIGN_UP_VERIFY]}?email=${encodeURIComponent(
            user.username,
          )}`,
        );
        return;
      }

      setError(loginError);
    }
  }, [login, push, setError, user]);

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
      addonFooter={<Login.Footer items={useFooter()} />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.FormTemplate
        addonPasswordAfter={
          <div className={styles['options']}>
            <Checkbox
              classNameLabel={styles['remember-label']}
              classNameLabelHover={styles['remember-label_hover']}
              classNameLabelFocus={styles['remember-label_focus']}
              checked={user[User.REMEMBER_ME]}
              hasSelfFocus={false}
              hasSelfHover={false}
              name={User.REMEMBER_ME}
              onChange={handleChangeUser}
              label={translations.checkbox.rememberMe}
              theme="green"
            />
            <Login.Link href={paths[Page.APP_RESTORE]}>
              {translations.link.forgottenPassword}
            </Login.Link>
          </div>
        }
        addonControlsBottom={type !== LoginType.Editor && <GoogleSSO />}
        classNameErrors={styles['errors']}
        user={user}
        error={getLoginErrorMessage(translations.error, error)}
        loading={loading}
        onChangeUser={handleChangeUser}
        onSubmit={handleSubmit}
        hasError={hasError}
        ref={usernameRef}
        translations={translations}
      />
    </Login.Page>
  );
}

export default memo(AppLogin);
