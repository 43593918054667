import type { FormEvent } from 'react';
import { memo, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import { RightSection } from 'modules/SignUp';
import { TermsOfService } from 'modules/TermsOfService';
import { getAppSignUpVerify } from 'core/i18n';
import { useResendVerificationEmail } from 'core/api';
import { useMixpanel } from 'core/mixpanel';
import { paths, Page, useSearchParamValue } from 'core/routes';
import backgroundUrl from 'assets/images/sign-up-background.svg';
import { EventName } from './mixpanel';
import styles from './AppSignUpVerify.module.scss';

function AppSignUpVerify(): JSX.Element {
  const { push } = useHistory();
  const email = useSearchParamValue('email');
  const { loading, resendEmail } = useResendVerificationEmail();
  const [error, setError] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const translations = getAppSignUpVerify();
  const { track } = useMixpanel();

  useEffect(() => {
    if (!email) {
      push(`${paths[Page.APP_SIGN_UP]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (email) {
        const success = await resendEmail(email);

        if (error) {
          setError(false);
        }

        if (success) {
          setSent(true);
          track({
            eventName: EventName.CLICKED_ON_BUTTON_RESEND_EMAIL,
            properties: {
              WORK_EMAIL: email,
            },
          });
        } else {
          setError(true);
        }
      }
    },
    [email, error, resendEmail, track],
  );

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonFooter={<TermsOfService />}
      addonRightSection={<RightSection />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit} className={styles['container']}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle className={styles['subtitle']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <Login.Container type="content" className={styles['content']}>
          <div>{translations.content.emailSent}</div>
          <div className={styles['email']}>{email}</div>
          <div>{translations.content.checkInbox}</div>
          <div>
            {translations.content.checkSpam}
            <b>{translations.content.spamFolder}</b>
          </div>
        </Login.Container>
        <div className={styles['errors']}>
          {error && (
            <Login.Errors
              className={styles['errors__messages']}
              position={Login.ErrorPosition.Left}
              messages={[
                {
                  key: 'SOMETHING_WENT_WRONG',
                  value: translations.error,
                },
              ]}
            />
          )}
        </div>
        <Login.Container type="controls">
          <div className={styles['label']}>{translations.label}</div>
          <Login.ButtonSubmit
            label={sent ? translations.submit.sent : translations.submit.resend}
            loading={loading}
            disabled={!loading && sent}
          />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AppSignUpVerify);
