import { rocketIcon } from '@kameleoon/icons';
import { Modal, Button, Loader } from '@kameleoon/ui';
import type { FormEvent, ReactNode } from 'react';
import { memo, useState, useCallback } from 'react';
import { useUpdatePlan } from 'core/api';
import { Login } from 'modules/Login';
import { paths, Page, useSearchParamValue } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { EventName } from 'projects/app/AppFreeTrialError/mixpanel';
import { useHistory } from 'react-router-dom';
import { getAppFreeTrialExpiredTranslations } from 'core/i18n/app/getAppFreeTrialExpiredTranslations';
import styles from './ModalUpgrade.module.scss';

type Props = {
  onClose: () => void;
};

const iconSize = { width: 24, height: 22 };
const translations = getAppFreeTrialExpiredTranslations();

function ModalUpgrade(props: Props): JSX.Element {
  const [error, setError] = useState<boolean>(false);
  const [isSecondStep, setIsSecondStep] = useState<boolean>(false);
  const { size } = Modal.useDialog({
    size: {
      width: 520,
      height: 330,
    },
  });
  const email = useSearchParamValue('email');
  const { push } = useHistory();
  const { loading, updatedPlan } = useUpdatePlan();
  const { track } = useMixpanel();

  const handleUpdatePlan = useCallback(
    async (event: FormEvent<Element>) => {
      event.preventDefault();

      if (isSecondStep) {
        props.onClose();
        return;
      }

      if (error) {
        setError(false);
      }

      if (email) {
        const success = await updatedPlan(email);

        if (success) {
          setIsSecondStep(true);
          track({
            eventName: EventName.CLICKED_ON_CONFIRM,
            properties: {
              WORK_EMAIL: email,
            },
          });
        } else {
          setError(true);
        }
      } else {
        push(`${paths[Page.APP_SIGN_UP]}`);
      }
    },
    [isSecondStep, error, email, props, updatedPlan, track, push],
  );

  function getStep(): ReactNode {
    if (isSecondStep) {
      return (
        <div className={styles['success']}>
          <b>{translations.modal.message.step2.message1}</b> <br />
          {translations.modal.message.step2.message2}
        </div>
      );
    }

    return translations.modal.message.step1;
  }

  function getSumbitButtonLabel(): ReactNode {
    if (loading) {
      return <Loader className={styles['loader']} />;
    }

    if (isSecondStep) {
      return translations.modal.button.ok;
    }

    return translations.modal.button.confirm;
  }

  return (
    <Modal.Overlay onClose={props.onClose}>
      <Modal.Dialog
        title={translations.modal.title}
        icon={rocketIcon}
        iconSize={iconSize}
        size={size}
        onClose={props.onClose}
      >
        <Modal.Form onSubmit={handleUpdatePlan}>
          <Modal.Title className={styles['form-title']}>
            {getStep()}
          </Modal.Title>
          <div className={styles['errors']}>
            {error && (
              <Login.Errors
                className={styles['errors__messages']}
                position={Login.ErrorPosition.Left}
                messages={[
                  {
                    key: 'SOMETHING_WENT_WRONG',
                    value: translations.modal.error,
                  },
                ]}
              />
            )}
          </div>
          <Modal.GroupButton>
            {!isSecondStep && (
              <Modal.Button>
                <Button onClick={props.onClose} theme="white" size="l">
                  {translations.modal.button.cancel}
                </Button>
              </Modal.Button>
            )}
            <Modal.Button>
              <Button
                disabled={loading}
                type="submit"
                theme="green"
                size="l"
                className={styles['button']}
              >
                {getSumbitButtonLabel()}
              </Button>
            </Modal.Button>
          </Modal.GroupButton>
        </Modal.Form>
      </Modal.Dialog>
    </Modal.Overlay>
  );
}

export default memo(ModalUpgrade);
