/* eslint-disable no-console */
import type { ReactNode } from 'react';
import { useEffect, useMemo, useCallback } from 'react';
import { MIXPANEL_TOKEN } from 'core/env';
import mixpanel from 'mixpanel-browser';
import type { MixpanelTrackType } from './types';
import { MixpanelContext } from './MixpanelContext';

type Props = {
  children: ReactNode;
};

export function MixpanelProvider({ children }: Props): JSX.Element {
  const checkInit = useCallback(() => {
    try {
      mixpanel.get_config();
      return true;
    } catch {
      return false;
    }
  }, []);

  useEffect(() => {
    if (MIXPANEL_TOKEN && !checkInit()) {
      try {
        mixpanel.init(MIXPANEL_TOKEN);
      } catch (error) {
        console.error('Mixpanel', error);
      }
    }
  }, [checkInit]);

  const track = useCallback(
    ({ eventName, properties }: MixpanelTrackType) => {
      if (MIXPANEL_TOKEN && checkInit()) {
        try {
          mixpanel.track(eventName, properties);
        } catch (error) {
          console.error('Mixpanel', error);
        }
      } else {
        console.log(
          'Mixpanel is in development mode, the following data will be sent on production mode:',
        );
        console.log('%c  eventName:', 'color: green', eventName);
        if (typeof properties !== 'undefined') {
          console.log('%c  properties:', 'color: green', properties);
        }
      }
    },
    [checkInit],
  );

  const mixpanelActions = useMemo(
    () => ({
      track,
    }),
    [track],
  );

  return (
    <MixpanelContext.Provider value={mixpanelActions}>
      {children}
    </MixpanelContext.Provider>
  );
}
