import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loginAcademy } from 'core/api/loginAcademy';
import { LINK_ACADEMY, SearchParam } from 'core/constants';
import { Page, paths } from 'core/routes';

type HookResult = {
  getParamsAcademy: () => string | null;
  redirectToAcademy: () => Promise<void>;
  redirectToAcademyLogin: () => void;
};

type StorageParams = { [key: string]: string };

const STORAGE_NAME = 'academy';

export function useAcademyLogin(): HookResult {
  const { push } = useHistory();

  const getParamsAcademy = useCallback((): string | null => {
    return sessionStorage.getItem(STORAGE_NAME);
  }, []);

  useEffect(() => {
    if (window.location.href.includes(paths[Page.ACADEMY_LOGIN])) {
      const currentParams = new URLSearchParams(window.location.search);
      const paramsObject: StorageParams = {};

      currentParams.forEach((value, key) => {
        paramsObject[key] = value;
      });

      sessionStorage.setItem(STORAGE_NAME, JSON.stringify(paramsObject));
    }
  }, []);

  const redirectToAcademyLogin = useCallback(() => {
    sessionStorage.removeItem(STORAGE_NAME);
    push(paths[Page.ACADEMY_LOGIN]);
  }, [push]);

  const redirectToAcademy = useCallback(async () => {
    try {
      const token = await loginAcademy();

      if (token) {
        const params = getParamsAcademy();
        const url = new URL(LINK_ACADEMY);

        sessionStorage.removeItem(STORAGE_NAME);
        url.searchParams.set(SearchParam.TOKEN, token);

        if (params) {
          const paramsObject: StorageParams = JSON.parse(params);

          Object.keys(paramsObject).forEach((key) => {
            url.searchParams.set(key, paramsObject[key]);
          });
        }

        window.location.href = url.toString();

        return;
      }
    } catch (error) {
      throw new Error(JSON.stringify(error));
    }

    redirectToAcademyLogin();
  }, [getParamsAcademy, redirectToAcademyLogin]);

  return {
    getParamsAcademy,
    redirectToAcademy,
    redirectToAcademyLogin,
  };
}
