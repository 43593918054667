import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import { ErrorPosition } from '../constants';
import styles from './Errors.module.scss';

type Props = {
  className?: string;
  position?: ErrorPosition;
  messages: {
    key: string;
    value: ReactNode;
  }[];
};

function Errors({
  className,
  position = ErrorPosition.Right,
  messages,
}: Props): JSX.Element {
  return (
    <div
      className={classNames(
        styles['errors'],
        styles[`errors_${position}`],
        className,
      )}
    >
      {messages.map((message) => (
        <div
          className={classNames(
            styles['errors__message'],
            styles[`errors__message_${position}`],
          )}
          key={message.key}
        >
          {message.value}
        </div>
      ))}
    </div>
  );
}

export default memo(Errors);
