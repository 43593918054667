import type { TypeLoginFormTranslations } from 'modules/Login';
import type { LoginErrorMessagesType } from 'core/i18n';
import { getNavigatorLocale } from 'core/i18n';
import reactStringReplace from 'react-string-replace';
import { getAdminLocales } from './getAdminLocales';

export function getAdminLoginTranslations(): TypeLoginFormTranslations & {
  error: LoginErrorMessagesType;
  tab: { main: string };
} {
  const locale = getNavigatorLocale();
  const locales = getAdminLocales(locale);

  return {
    tab: {
      title: locales['access_management.admin.form.login.tab.title'],
      main: locales['access_management.admin.form.login.tab.main'],
    },
    title: locales['access_management.admin.form.login.title'],
    subtitle: locales['access_management.admin.form.admin.subtitle'],
    label: {
      username: locales['access_management.admin.form.label.username'],
      password: locales['access_management.admin.form.label.password'],
    },
    button: {
      login: locales['access_management.admin.form.button.log_in'],
    },
    tooltip: {
      password: reactStringReplace(
        locales['access_management.admin.form.tooltip.password'],
        '{br}',
        (_, index) => <br key={index} />,
      ),
    },
    aria: {
      password: {
        hide: locales['access_management.admin.form.aria.label.hide_password'],
        show: locales['access_management.admin.form.aria.label.show_password'],
        tooltip:
          locales['access_management.admin.form.aria.label.password_info'],
      },
    },
    error: {
      loginFailed:
        locales['access_management.admin.form.error.username_password'],
      sessionExpired:
        locales['access_management.admin.form.error.session_expired'],
      maximalPasswordAttempts:
        locales['access_management.admin.form.error.maximal_password_attempts'],
      temporarilyBlocked:
        locales['access_management.admin.form.error.temporarily_blocked'],
      pleaseReset: locales['access_management.admin.form.error.please_reset'],
      accountDeactivated:
        locales['access_management.admin.form.error.account_deactivated'],
      unauthorizedIpAddress:
        locales['access_management.admin.form.error.unauthorized_ip_address'],
      contantAdmin: locales['access_management.admin.form.error.contant_admin'],
    },
  };
}
