import { memo } from 'react';
import styles from './DeployInfo.module.scss';

type Props = {
  url: string;
};

function DeployInfo({ url }: Props): JSX.Element {
  return (
    <iframe className={styles['deploy-info']} src={url} title="deploy info" />
  );
}

export default memo(DeployInfo);
