import type { Selector } from 'core/constants';

export function getInput(selector: Selector, doc = document): HTMLInputElement {
  const input = doc.querySelector<HTMLInputElement>(selector);

  if (!input) {
    throw new Error(`Input element '${selector}' is required!`);
  }

  return input;
}
