import { Locale } from 'core/constants';

export function getNavigatorLocale(): Locale {
  const locale = window.navigator.language.slice(0, 2);
  const hasLocale = Object.values(Locale).includes(locale as Locale);

  if (hasLocale) {
    return locale as Locale;
  }

  return Locale.EN;
}
