import {
  getSsoErrorCodesTranslations,
  getSsoErrorTranslations,
} from 'core/i18n';
import type { SSOErrorPageTranslationsType } from 'modules/SSOErrorPage';
import { getAppLoginTranslations } from './getAppLoginTranslations';

export function getAppLoginSSOErrorGoogleTranslations(): SSOErrorPageTranslationsType {
  const locales = getAppLoginTranslations();
  const ssoCodes = getSsoErrorCodesTranslations();
  const ssoError = getSsoErrorTranslations({
    tabMain: locales.tab.main,
    error: ssoCodes.notMatchKameleoonAccount,
  });

  return ssoError;
}
