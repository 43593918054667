import type { ChangeEvent } from 'react';
import { useState, useCallback, memo, forwardRef } from 'react';
import type { TypeTextFieldPayload } from '@kameleoon/ui';
import { TextField } from '@kameleoon/ui';
import classNames from 'classnames';
import { PasswordToogler } from '../PasswordToogler';
import { getFieldClassNames, getFieldControlClassNames } from './helpers';
import styles from './Field.module.scss';

type Props = {
  aria: {
    hide: string;
    show: string;
  };
  autocomplete?: string;
  className?: string;
  classNameControl?: string;
  hasError?: boolean;
  id?: string;
  loading?: boolean;
  name?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    payload: TypeTextFieldPayload,
  ) => void;
  placeholder?: string;
  value?: string;
};

const Password = forwardRef<HTMLInputElement, Props>(function Password(
  {
    aria,
    autocomplete = 'current-password',
    className,
    classNameControl,
    hasError,
    id,
    loading,
    name,
    onChange,
    placeholder,
    value,
  },
  ref,
): JSX.Element {
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const handleToggleShowPassword = useCallback(() => {
    setIsPasswordShown((prevIsPasswordShown) => !prevIsPasswordShown);
  }, []);

  return (
    <TextField
      className={classNames(getFieldClassNames(hasError), className)}
      classNameControl={classNames([
        getFieldControlClassNames(hasError),
        { [styles['password']]: !isPasswordShown },
        classNameControl,
      ])}
      addonAfter={
        !loading && (
          <PasswordToogler
            isShown={isPasswordShown}
            onClick={handleToggleShowPassword}
            aria={aria}
          />
        )
      }
      autocomplete={autocomplete}
      disabled={loading}
      hasError={hasError}
      id={id}
      name={name}
      onChange={onChange}
      ref={ref}
      size="l"
      spellcheck={false}
      type={!loading && isPasswordShown ? 'text' : 'password'}
      placeholder={placeholder}
      value={value}
    />
  );
});

export default memo(Password);
