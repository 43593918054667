import type { FormEvent, MouseEvent } from 'react';
import { useState, memo, useCallback } from 'react';
import { Login } from 'modules/Login';
import { RightSection } from 'modules/SignUp';
import { TermsOfService } from 'modules/TermsOfService';
import backgroundUrl from 'assets/images/sign-up-background.svg';
import errorImageUrl from 'assets/images/free-trial-error.svg';
import { getAppFreeTrialExpiredTranslations } from 'core/i18n/app/getAppFreeTrialExpiredTranslations';
import { Title } from 'modules/Title';
import { Button } from '@kameleoon/ui';
import { useMixpanel } from 'core/mixpanel';
import { useSearchParamValue } from 'core/routes';
import { EventName } from './mixpanel';
import styles from './AppFreeTrialError.module.scss';
import { ModalUpgrade } from './ModalUpgrade';

const translations = getAppFreeTrialExpiredTranslations();

function AppFreeTrialError(): JSX.Element {
  const [hasModalUpgrade, sethasModalUpgrade] = useState<boolean>(false);
  const { track } = useMixpanel();
  const email = useSearchParamValue('email');

  // const handleClickDiscoverFullStack = useCallback(
  //   (event: MouseEvent<HTMLButtonElement>) => {
  //     event.preventDefault();
  //   },
  //   [],
  // );

  function handleCloseModal(): void {
    sethasModalUpgrade(false);
  }

  const handleSubmitForm = useCallback(
    (event: FormEvent<Element>) => {
      event.preventDefault();
      sethasModalUpgrade(true);
      track({
        eventName: EventName.CLICKED_ON_UPGRADE_NOW,
        properties: {
          WORK_EMAIL: email,
        },
      });
    },
    [email, track],
  );

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<RightSection />}
      addonFooter={<TermsOfService />}
    >
      <Title>{`${translations.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmitForm}>
        <Login.Container type="title">
          <Login.Title className={styles['title']}>
            {translations.title}
          </Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle className={styles['subtitle']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <Login.Container type="content">
          <img
            src={errorImageUrl}
            className={styles['image']}
            alt="free-trial-error"
          />
        </Login.Container>
        <Login.Container type="content">
          <span className={styles['before-controls']}>
            {translations.content.beforeControls}
          </span>
        </Login.Container>
        <Login.Container className={styles['controls']} type="controls">
          <Login.ButtonSubmit label={translations.button.upgrade} />
          {/* <Button
            className={styles['button-secondary']}
            size="l"
            theme="white"
            onClick={handleClickDiscoverFullStack}
          >
            {translations.button.discover}
          </Button> */}
        </Login.Container>
        <Login.Container type="content">
          <span className={styles['after-controls']}>
            {translations.content.afterControls.text}{' '}
            <a
              className={styles['link']}
              href={translations.content.afterControls.link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translations.content.afterControls.link.text}
            </a>
            .
          </span>
        </Login.Container>
      </Login.Form>
      {hasModalUpgrade && <ModalUpgrade onClose={handleCloseModal} />}
    </Login.Page>
  );
}

export default memo(AppFreeTrialError);
