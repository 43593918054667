import { useState, useCallback } from 'react';
import { verifyAccount } from './verifyAccount';
import { getAccountIdByToken } from './getAccountId';

type TypeLoginHookResult = {
  loading: boolean;
  verify: (token: string) => Promise<boolean>;
};

export function useSignUpVerifyAccount(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const verify = useCallback(async (token: string) => {
    setLoading(true);

    try {
      const { id } = await getAccountIdByToken(token);

      if (id) {
        return await verifyAccount(id, token);
      }

      return false;
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    verify,
  };
}
