import { useCallback, memo } from 'react';
import { Button, Icon } from '@kameleoon/ui';
import classNames from 'classnames';
import { GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { getAppLoginTranslations } from 'core/i18n';
import { googleIcon } from './googleIcon';
import { googleDisabledIcon } from './googleDisabledIcon';
import { EventName } from './mixpanel';
import styles from './GoogleSSO.module.scss';

type Props = {
  disabled?: boolean;
};

function GoogleSSO({ disabled }: Props): JSX.Element {
  const { track } = useMixpanel();
  const translations = getAppLoginTranslations();

  const handleClick = useCallback(() => {
    track({
      eventName: EventName.CLICKED_ON_BUTTON_CONTINUE_WITH_GOOGLE,
    });
    window.location.href = `${GRAVITEE_URL}${
      paths[Page.APP_LOGIN_SSO_GOOGLE_INTERNAL]
    }`;
  }, [track]);

  const { path, viewBox } = disabled ? googleDisabledIcon : googleIcon;

  return (
    <div className={styles['google']}>
      <div className={styles['or']}>
        <hr className={styles['or__line']} />
        <div className={styles['or__label']}>{translations.or}</div>
        <hr className={styles['or__line']} />
      </div>
      <Button
        className={classNames(styles['button'], {
          [styles['button_disabled']]: disabled,
        })}
        size="l"
        theme="white"
        onClick={handleClick}
        disabled={disabled}
      >
        <Icon
          className={classNames(styles['button__icon'], {
            [styles['button__icon_disabled']]: disabled,
          })}
          path={path}
          viewBox={viewBox}
        />
        {translations.button.continueWithGoogle}
      </Button>
    </div>
  );
}

export default memo(GoogleSSO);
