import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getAppSignUpVerify(): {
  tab: {
    main: string;
    title: string;
  };
  title: string;
  subtitle: string;
  content: {
    emailSent: string;
    checkInbox: string;
    checkSpam: string;
    spamFolder: string;
  };
  submit: {
    resend: string;
    sent: string;
  };
  label: string;
  error: string;
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      main: locales['access_management.app.form.login.tab.main'],
      title: locales['access_management.app.form.verify.tab.title'],
    },
    title: locales['access_management.app.form.verify.title'],
    subtitle: locales['access_management.app.form.verify.subtitle'],
    content: {
      emailSent: locales['access_management.app.form.verify.content.emailSent'],
      checkInbox:
        locales['access_management.app.form.verify.content.checkInbox'],
      checkSpam: locales['access_management.app.form.verify.content.checkSpam'],
      spamFolder:
        locales['access_management.app.form.verify.content.spam_folder'],
    },
    submit: {
      resend: locales['access_management.app.form.verify.submit'],
      sent: locales['access_management.app.form.verify.submit_sent'],
    },
    label: locales['access_management.app.form.verify.label'],
    error:
      locales['access_management.app.form.sign_up.error.something_went_wrong'],
  };
}
