import type { ChangeEvent, FormEvent } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Icon, TextField } from '@kameleoon/ui';
import { checkIcon } from '@kameleoon/icons';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import { useLoginMfa, useResendMfaCode } from 'core/api';
import { SearchParam } from 'core/constants';
import { getAppLoginMfaVerifyTranslations } from 'core/i18n';
import {
  Page,
  paths,
  useRedirectAfterLogin,
  useSearchParamValue,
} from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { APP_REDIRECT_URL } from 'core/env';
import backgroundUrl from 'assets/images/app-background.svg';
import styles from './AppLoginMfaVerify.module.scss';
import { EventName } from './mixpanel';

const MAX_NUM_RESEND_CODE = 5;

function AppLoginMfaVerify(): JSX.Element {
  const { track } = useMixpanel();
  const factorId = useSearchParamValue(SearchParam.FACTOR_ID);
  const email = useSearchParamValue(SearchParam.EMAIL);
  const [isError, setIsError] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const { loading, verifiedCode } = useLoginMfa();
  const redirectUri = APP_REDIRECT_URL;
  const redirectToAppAfterLogin = useRedirectAfterLogin({
    page: Page.APP_LOGIN,
    redirectUri,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const { resend, inscreaseAttempts, attempts, timer } =
    useResendMfaCode(redirectUri);

  const translations = getAppLoginMfaVerifyTranslations(email);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    track({
      eventName: EventName.ENTERED_THE_OTP_FORM_PAGE,
    });
  }, [track]);

  useEffect(() => {
    if (attempts === MAX_NUM_RESEND_CODE) {
      track({
        eventName: EventName.REACHED_MAXIMUM_OTP_ATTEMPTS,
      });
    }
  }, [attempts, track]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (factorId) {
        const { error } = await verifiedCode({
          code,
          factorId,
          callback: () => {
            window.location.href = redirectToAppAfterLogin;
          },
        });

        if (error) {
          setIsError(true);
          setCode('');
          inscreaseAttempts();
          inputRef.current?.focus();
          track({
            eventName: EventName.SUBMITTED_WRONG_OTP_CODE,
          });
        }

        track({
          eventName: EventName.CLICKED_ON_VERIFY_CODE,
        });
      }
    },
    [
      factorId,
      code,
      redirectToAppAfterLogin,
      track,
      verifiedCode,
      inscreaseAttempts,
    ],
  );

  const handleChangeTextField = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      const regex = /^[0-9]*$/;

      if (regex.test(event.target.value)) {
        setCode(event.target.value);

        if (isError) {
          setIsError(false);
        }
      }
    },
    [isError],
  );

  function getFooter(): JSX.Element {
    return (
      <p className={styles['footer']}>
        {translations.footer.content}
        <a
          href={translations.footer.link.href}
          target="blank"
          className={styles['button']}
        >
          {translations.footer.link.text}
        </a>
      </p>
    );
  }

  if (!factorId) {
    return <Redirect to={paths[Page.APP_LOGIN]} />;
  }

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
      addonFooter={getFooter()}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle className={styles['subtitle']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <Login.Container type="content" className={styles['content']}>
          {translations.content.label}
        </Login.Container>
        <TextField
          className={styles['input']}
          classNameControl={styles['control']}
          size="l"
          hasError={isError}
          onChange={handleChangeTextField}
          placeholder="******"
          pattern="[0-9]{6}"
          maxLength={6}
          ref={inputRef}
          value={code}
          disabled={attempts >= MAX_NUM_RESEND_CODE}
        />
        <div className={styles['error']}>
          {isError && (
            <Login.Errors
              position={Login.ErrorPosition.Center}
              messages={[
                {
                  key: 'INVALID_CODE',
                  value: translations.notifications.invalidCode,
                },
              ]}
            />
          )}
        </div>
        <Login.Container type="controls">
          <Login.ButtonSubmit
            label={translations.submit}
            loading={loading}
            disabled={code.length < 6 || attempts >= MAX_NUM_RESEND_CODE}
          />
        </Login.Container>
        <p className={styles['after-controls']}>
          {translations.content.question}
          <button
            type="button"
            className={styles['button']}
            onClick={resend}
            disabled={timer > 0 || attempts >= MAX_NUM_RESEND_CODE}
          >
            {translations.content.link}
          </button>
          <p className={styles['timer']}>{timer > 0 && `(00:${timer})`}</p>
        </p>
      </Login.Form>
      <div className={styles['notification']}>
        {isError && (
          <div className={styles['error-notification']}>
            <div className={styles['error-icon']}>!</div>
            <div>
              {attempts >= MAX_NUM_RESEND_CODE
                ? translations.notifications.error5attempts
                : translations.notifications.warning}
            </div>
          </div>
        )}
        {timer > 0 && (
          <div className={styles['success-notification']}>
            <div className={styles['success-icon']}>
              <Icon
                className={styles['success-icon__svg']}
                path={checkIcon.path}
                viewBox={checkIcon.viewBox}
              />
            </div>
            <div>{translations.notifications.successRecentCode}</div>
          </div>
        )}
      </div>
    </Login.Page>
  );
}

export default memo(AppLoginMfaVerify);
