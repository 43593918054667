import type { FormEvent } from 'react';
import { useRef, useState, useEffect, useCallback, memo } from 'react';
import { getAdminSSOTranslations } from 'core/i18n';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import { GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';
import backgroundUrl from 'assets/images/app-background.svg';

const translations = getAdminSSOTranslations();

function AdminSSO(): JSX.Element {
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    buttonSubmitRef.current?.focus();
  }, []);

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      window.location.href = `${GRAVITEE_URL}${paths[Page.ADMIN_SSO_INTERNAL]}`;
    } catch {
      setLoading(false);
    }
  }, []);

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle>{translations.subtitle}</Login.Subtitle>
        </Login.Container>
        <Login.Container type="controls">
          <Login.ButtonSubmit
            label={translations.button.login}
            loading={loading}
            ref={buttonSubmitRef}
          />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AdminSSO);
