import type { FormEvent } from 'react';
import { useRef, useState, useEffect, useCallback, memo } from 'react';
import { Errors, SearchParam } from 'core/constants';
import { getAppAdminSSOTranslations } from 'core/i18n';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import { User } from 'modules/Login/constants';
import { GRAVITEE_URL } from 'core/env';
import {
  paths,
  Page,
  useSearchParamValue,
  APP_ADMIN_SSO_REDIRECT_KEY,
} from 'core/routes';
import backgroundUrl from 'assets/images/app-background.svg';

const translations = getAppAdminSSOTranslations();

function AdminSSO(): JSX.Element {
  const customerRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const redirect = useSearchParamValue(SearchParam.REDIRECT);
  const {
    user,
    error,
    hasError,
    setUser: handleChangeUser,
    setError,
  } = Login.useUser();

  useEffect(() => {
    customerRef.current?.focus();
  }, []);

  useEffect(() => {
    if (redirect) {
      sessionStorage.setItem(APP_ADMIN_SSO_REDIRECT_KEY, redirect);
    } else {
      sessionStorage.removeItem(APP_ADMIN_SSO_REDIRECT_KEY);
    }
  }, [redirect]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (user.customer === '') {
        setError(Errors.MISSING_FIELDS);
        customerRef.current?.focus();
        return;
      }

      try {
        setLoading(true);

        const url = new URL(
          `${GRAVITEE_URL}${paths[Page.APP_ADMIN_SSO_INTERNAL]}`,
        );
        url.searchParams.set(
          SearchParam.IMPERSONATE_ACCOUNT,
          String(user[User.CUSTOMER]).trim(),
        );
        window.location.href = url.toString();
      } catch {
        setLoading(false);
      }
    },
    [setError, user],
  );

  const getErrorMessage = useCallback(() => {
    switch (error) {
      case Errors.MISSING_FIELDS:
        return [
          {
            key: Errors.LOGIN_FAILED,
            value: translations.error.loginFailed,
          },
        ];
      default:
        return [];
    }
  }, [error]);

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle>{translations.subtitle}</Login.Subtitle>
        </Login.Container>
        <Login.Container type="password">
          <Login.Label htmlFor={User.CUSTOMER}>
            {translations.label.customer}
          </Login.Label>
          <Login.Field
            autocomplete="username"
            id={User.CUSTOMER}
            hasError={hasError}
            loading={loading}
            name={User.CUSTOMER}
            onChange={handleChangeUser}
            ref={customerRef}
            value={user[User.CUSTOMER]}
          />
        </Login.Container>
        <Login.Errors messages={getErrorMessage()} />
        <Login.Container type="controls">
          <Login.ButtonSubmit
            label={translations.button.login}
            loading={loading}
          />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AdminSSO);
