import { Errors } from 'core/constants';
import { REST_URL } from 'core/env';

function getError(error: string): Errors {
  try {
    switch (error) {
      case Errors.MAX_PASSWORD_ATTEMPTS:
      case Errors.UNAUTHORIZED_IP_ADDRESS:
      case Errors.BAD_CREDENTIALS:
      case Errors.ACCOUNT_DEACTIVATED:
      case Errors.FREE_TRIAL_ENDED:
      case Errors.FREE_TRIAL_INACTIVE_ACCOUNT:
      case Errors.UNDEFINED_ERROR:
        return error;
      default:
        return Errors.UNDEFINED_ERROR;
    }
  } catch {
    return Errors.UNDEFINED_ERROR;
  }
}

export async function getAuthError(
  username: string,
): Promise<{ error: Errors }> {
  try {
    const response = await fetch(
      `${REST_URL}/accounts/authenticationError?username=${encodeURIComponent(
        username,
      )}`,
      { method: 'GET' },
    );
    const error = await response.text();

    return {
      error: getError(error),
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
