import type { ReactNode } from 'react';
import { EmailError } from 'core/api';
import { getAppSignUpTranslations } from 'core/i18n';
import type { ErrorStatusType } from './types';
import { ErrorStatus } from './types';

const translations = getAppSignUpTranslations();

type UseErrorsMessagesHookResult = {
  key: string;
  value: ReactNode;
}[];

export function useErrorsMessages(
  status: ErrorStatusType,
): UseErrorsMessagesHookResult {
  switch (status) {
    case ErrorStatus.OneField: {
      return [{ key: status, value: translations.error.field.one }];
    }
    case ErrorStatus.SeveralFields: {
      return [{ key: status, value: translations.error.field.several }];
    }
    case ErrorStatus.SomethingWentWrong: {
      return [{ key: status, value: translations.error.somethingWentWrong }];
    }
    case EmailError.EmailExistsPremiumUser: {
      return [{ key: status, value: translations.error.email.premium }];
    }
    case EmailError.EmailExists: {
      return [{ key: status, value: translations.error.email.freeTrial }];
    }
    case EmailError.EmailIsFake: {
      return [{ key: status, value: translations.error.email.blacklisted }];
    }
    default: {
      return [];
    }
  }
}
