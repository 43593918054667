import { REST_URL } from 'core/env';

export async function getAccountIdByToken(
  token: string,
): Promise<{ id?: string; hasError: boolean }> {
  try {
    const response = await fetch(`${REST_URL}/accounts/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    return {
      id: data.id,
      hasError: response.status === 200,
    };
  } catch (error) {
    return {
      hasError: true,
    };
  }
}
