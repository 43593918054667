import { Page, useSearchParamValue } from 'core/routes';
import { SearchParam } from 'core/constants';

type Params = {
  page: Page;
  redirectUri: string;
};

export function useRedirectAfterLogin({ page, redirectUri }: Params): string {
  const url = new URL(redirectUri);
  const redirect = useSearchParamValue(SearchParam.REDIRECT);

  if (redirect) {
    url.searchParams.set(SearchParam.REDIRECT, redirect);
  }

  if (page === Page.APP_ADMIN) {
    url.searchParams.set(SearchParam.LOGIN_TYPE, page);
  }

  return url.toString();
}
