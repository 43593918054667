import { getNavigatorLocale } from 'core/i18n';
import type { SSOErrorPageTranslationsType } from 'modules/SSOErrorPage';
import { getSsoErrorLocales } from './getSsoErrorLocales';

type SsoErrorParamsType = {
  tabMain: string;
  error: string;
};

export function getSsoErrorTranslations({
  tabMain,
  error,
}: SsoErrorParamsType): SSOErrorPageTranslationsType {
  const locale = getNavigatorLocale();
  const locales = getSsoErrorLocales(locale);

  return {
    tab: {
      title: locales['access_management.sso_error.page.title'],
      main: tabMain,
    },
    title: locales['access_management.sso_error.page.title'],
    subtitle: error,
    submit: locales['access_management.sso_error.page.button.submit'],
  };
}
