import { ButtonSubmit } from './ButtonSubmit';
import { Container } from './Container';
import { DeployInfo } from './DeployInfo';
import { Errors } from './Errors';
import { Field, Password, Select } from './Field';
import { Footer } from './Footer';
import { Form } from './Form';
import { FormSuccess } from './FormSuccess';
import { FormTemplate } from './FormTemplate';
import { Label } from './Label';
import { Link } from './Link';
import { DemoLink } from './DemoLink';
import { Page } from './Page';
import { PasswordToogler } from './PasswordToogler';
import { Rocket } from './Rocket';
import { Subtitle } from './Subtitle';
import { Title } from './Title';
import { Tooltip } from './Tooltip';
import { useUser } from './useUser';
import { useError } from './useError';
import { ErrorPosition } from './constants';

export type {
  TypeLoginFormTranslations,
  TypeLoginPageTranslations,
  TypeError,
  TypeErrorMessage,
} from './types';
export type { LoginFormTemplateRef } from './FormTemplate';

export const Login = {
  ButtonSubmit,
  Container,
  DeployInfo,
  Errors,
  Field,
  Footer,
  Form,
  FormSuccess,
  FormTemplate,
  Label,
  Link,
  DemoLink,
  Page,
  PasswordToogler,
  Password,
  Rocket,
  Select,
  Subtitle,
  Title,
  Tooltip,
  useUser,
  useError,
  ErrorPosition,
};
