import { REST_URL } from 'core/env';

export async function loginAcademy(): Promise<string> {
  try {
    const response = await fetch(`${REST_URL}/accounts/academy-login`, {
      method: 'GET',
      credentials: 'include',
    });

    return await response.text();
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
