import { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { paths, Page } from 'core/routes';
import { getAppAdminSSOErrorTranslations } from 'core/i18n';
import { SSOErrorPage } from 'modules/SSOErrorPage';

function AppAdminSSOError(): JSX.Element {
  const { push } = useHistory();
  const translations = getAppAdminSSOErrorTranslations();

  const handleSubmit = useCallback(() => {
    push(paths[Page.APP_ADMIN_SSO]);
  }, [push]);

  return <SSOErrorPage onSubmit={handleSubmit} translations={translations} />;
}

export default memo(AppAdminSSOError);
