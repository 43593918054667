import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSSOAuthorize, useAcademyLogin } from 'core/api';
import { APP_REDIRECT_URL, GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { EventName } from './mixpanel';

function AppLoginSSOAuthorizeGoogle(): null {
  const { push } = useHistory();
  const { track } = useMixpanel();
  const { getParamsAcademy, redirectToAcademy, redirectToAcademyLogin } =
    useAcademyLogin();

  useSSOAuthorize({
    redirectUri: `${GRAVITEE_URL}${paths[Page.APP_LOGIN_SSO_GOOGLE_AUTHORIZE]}`,
    rememberMe: true,
    callback: ({ hasAuthSSOError }) => {
      if (hasAuthSSOError) {
        track({
          eventName: EventName.WAS_REDIRECTED_TO_THE_GOOGLE_SSO_ERROR_PAGE,
        });
        push(paths[Page.APP_LOGIN_SSO_GOOGLE_ERROR]);
        return;
      }

      if (getParamsAcademy() !== null) {
        redirectToAcademy();
        return;
      }

      window.location.href = APP_REDIRECT_URL;
    },
    catchCallback: () => {
      if (getParamsAcademy()) {
        redirectToAcademyLogin();
        return;
      }

      push(paths[Page.APP_LOGIN]);
    },
  });

  return null;
}

export default memo(AppLoginSSOAuthorizeGoogle);
