import { Errors, FormKey, SearchParam, Selector } from 'core/constants';
import { GRAVITEE_URL } from 'core/env';
import { getInput } from 'core/helpers';

type Params = {
  code: string;
  factorId: string;
};

function getData(params: Params): FormData {
  const data = new FormData();

  data.append(FormKey.CODE, params.code);
  data.append(FormKey.CSRF_TOKEN, getInput(Selector.CSRF_TOKEN).value);
  data.append(FormKey.FACTOR_ID, params.factorId);

  return data;
}

export async function loginMfa(
  params: Params,
  redirectUri: string,
): Promise<{
  code: string | null;
  error: Errors | null;
  csrfToken: string | null;
}> {
  try {
    const clientId = getInput(Selector.CLIENT_ID).value;
    const response = await fetch(
      `${GRAVITEE_URL}/am/kameleoon/mfa/challenge?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`,
      {
        method: 'POST',
        body: getData(params),
      },
    );

    if (response.status === 500) {
      return {
        code: null,
        error: Errors.SERVER_ERROR,
        csrfToken: null,
      };
    }

    const url = new URL(response.url);
    const parser = new DOMParser();
    const htmlText = await response.text();
    const doc = parser.parseFromString(htmlText, 'text/html');
    const csrfToken = doc.querySelector<HTMLInputElement>(Selector.CSRF_TOKEN);

    const searchParams = new URLSearchParams(url.searchParams);
    const code = searchParams.get(SearchParam.CODE);
    const graviteeError = searchParams.get(SearchParam.ERROR);

    return {
      code,
      error: graviteeError ? Errors.INVALID_CODE : null,
      csrfToken: csrfToken?.value || null,
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
