import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getAppConfirmationTranslations(): {
  tab: {
    main: string;
  };
  title: string;
  subtitle: string;
  button: {
    logIn: string;
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.confirmation_page.title'],
    subtitle: locales['access_management.app.form.confirmation_page.subtitle'],
    button: {
      logIn:
        locales['access_management.app.form.confirmation_page.button.log_in'],
    },
  };
}
