import type { ChangeEvent } from 'react';
import { memo, forwardRef } from 'react';
import type { TypeTextFieldPayload } from '@kameleoon/ui';
import { TextField } from '@kameleoon/ui';
import classNames from 'classnames';
import { getFieldClassNames, getFieldControlClassNames } from './helpers';

type Props = {
  autocomplete?: string;
  className?: string;
  classNameControl?: string;
  hasError?: boolean;
  id?: string;
  loading?: boolean;
  name?: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement>,
    payload: TypeTextFieldPayload,
  ) => void;
  placeholder?: string;
  value?: string;
};

const Field = forwardRef<HTMLInputElement, Props>(function Field(
  {
    autocomplete,
    className,
    classNameControl,
    hasError,
    id,
    loading,
    name,
    onChange,
    placeholder,
    value,
  },
  ref,
): JSX.Element {
  return (
    <TextField
      className={classNames(getFieldClassNames(hasError), className)}
      classNameControl={classNames(
        getFieldControlClassNames(hasError),
        classNameControl,
      )}
      autocomplete={autocomplete}
      disabled={loading}
      hasError={hasError}
      id={id}
      name={name}
      onChange={onChange}
      ref={ref}
      size="l"
      spellcheck={false}
      placeholder={placeholder}
      value={value}
    />
  );
});

export default memo(Field);
