import { memo } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  children: string;
};

function Title({ children }: Props): JSX.Element {
  return (
    <Helmet defer={false}>
      <title>{children}</title>
    </Helmet>
  );
}

export default memo(Title);
