export enum FormKey {
  USERNAME = 'username',
  PASSWORD = 'password',
  CSRF_TOKEN = 'X-XSRF-TOKEN',
  CLIENT_ID = 'client_id',
  EMAIL_FACT = 'emailFact0',
  SHARED_SECRET = 'sharedSecret',
  FACTOR_ID = 'factorId',
  CODE = 'code',
}
