import {
  getSsoErrorCodesTranslations,
  getSsoErrorTranslations,
} from 'core/i18n';
import type { SSOErrorPageTranslationsType } from 'modules/SSOErrorPage';
import { getAppAdminSSOTranslations } from './getAppAdminSSOTranslations';

export function getAppAdminSSOErrorTranslations(): SSOErrorPageTranslationsType {
  const locales = getAppAdminSSOTranslations();
  const ssoCodes = getSsoErrorCodesTranslations();
  const ssoError = getSsoErrorTranslations({
    tabMain: locales.tab.main,
    error: ssoCodes.loginFailed,
  });

  return ssoError;
}
