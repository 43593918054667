import type { TypeLoginFormTranslations } from 'modules/Login';
import reactStringReplace from 'react-string-replace';
import type { LoginErrorMessagesType } from 'core/i18n';
import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getAppAdminTranslations(): TypeLoginFormTranslations & {
  tab: { main: string };
  label: { customer: string };
  error: LoginErrorMessagesType;
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.admin.tab.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.admin.title'],
    subtitle: locales['access_management.app.form.admin.subtitle'],
    label: {
      customer:
        locales['access_management.app.form.admin.label.username.customer'],
      username:
        locales['access_management.app.form.admin.label.username.admin'],
      password:
        locales['access_management.app.form.admin.label.password.admin'],
    },
    button: {
      login: locales['access_management.app.form.button.log_in'],
    },
    tooltip: {
      password: reactStringReplace(
        locales['access_management.app.form.tooltip.password'],
        '{br}',
        () => <br />,
      ),
    },
    aria: {
      password: {
        hide: locales['access_management.app.form.aria.label.hide_password'],
        show: locales['access_management.app.form.aria.label.show_password'],
        tooltip: locales['access_management.app.form.aria.label.password_info'],
      },
    },
    error: {
      loginFailed:
        locales['access_management.app.form.error.username_password'],
      sessionExpired:
        locales['access_management.app.form.error.session_expired'],
      maximalPasswordAttempts:
        locales['access_management.app.form.error.maximal_password_attempts'],
      temporarilyBlocked:
        locales['access_management.app.form.error.temporarily_blocked'],
      pleaseReset: locales['access_management.app.form.error.please_reset'],
      accountDeactivated:
        locales['access_management.app.form.error.account_deactivated'],
      unauthorizedIpAddress:
        locales['access_management.app.form.error.unauthorized_ip_address'],
      contantAdmin: locales['access_management.app.form.error.contant_admin'],
    },
  };
}
