import { REST_URL } from 'core/env';

export type SignUpParams = {
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  projectUrl: string;
  password: string;
  recaptchaResponseToken: string;
  promocode?: string;
};

export async function signUp(params: SignUpParams): Promise<boolean> {
  try {
    const response = await fetch(`${REST_URL}/customers/freeTrial`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    return response.status === 200 || response.status === 201;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
