import { memo } from 'react';
import { Icon } from '@kameleoon/ui';
import { useFocus } from '@kameleoon/hooks';
import { showIcon, hideIcon } from '@kameleoon/icons';
import classNames from 'classnames';
import styles from './PasswordToogler.module.scss';

type Props = {
  isShown: boolean;
  onClick: () => void;
  aria: {
    hide: string;
    show: string;
  };
};

function PasswordToogler({ isShown, onClick, aria }: Props): JSX.Element {
  const [buttonRef, focused] = useFocus<HTMLButtonElement>('keyboard');
  const { path, viewBox } = isShown ? hideIcon : showIcon;

  return (
    <button
      className={classNames(styles['toggler'], {
        [styles['toggler_focus']]: focused,
      })}
      aria-label={isShown ? aria.hide : aria.show}
      onClick={onClick}
      type="button"
      ref={buttonRef}
    >
      <Icon className={styles['toggler__icon']} path={path} viewBox={viewBox} />
    </button>
  );
}

export default memo(PasswordToogler);
