import { render } from 'react-dom';
import { MixpanelProvider } from 'core/mixpanel';
import App from './App';
import './index.scss';

render(
  <MixpanelProvider>
    <App />
  </MixpanelProvider>,
  document.getElementById('root'),
);
