import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import { Link } from '../Link';
import type { DemoLinkType } from './types';
import styles from './DemoLink.module.scss';

type Props = {
  className?: string;
  message: ReactNode;
  link: DemoLinkType;
};

function DemoLink({
  className,
  message,
  link: { external = true, href, text },
}: Props): JSX.Element {
  return (
    <div className={classNames(styles['demo-link'], className)}>
      <span className={styles['message']}>{message}</span>
      <Link
        external={external}
        className={styles['link']}
        href={href}
        target="_blank"
      >
        {text}
      </Link>
    </div>
  );
}

export default memo(DemoLink);
