import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Subtitle.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
};

function Subtitle({ children, className }: Props): JSX.Element {
  return (
    <h2 className={classNames(styles['subtitle'], className)}>{children}</h2>
  );
}

export default memo(Subtitle);
