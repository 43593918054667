import type { Errors } from 'core/constants';
import { FormKey, Selector, SearchParam } from 'core/constants';
import { GRAVITEE_URL } from 'core/env';
import { getError } from './getError';

type LoginParams = {
  [FormKey.USERNAME]: string;
  [FormKey.PASSWORD]: string;
  [FormKey.CSRF_TOKEN]: string;
  [FormKey.CLIENT_ID]: string;
};

export type LoginResponse = {
  error: Errors | null;
  code: string | null;
  csrfToken: string | null;
  factorId: string | null;
  sharedSecret: string | null;
};

function getFormData(params: LoginParams): FormData {
  const formData = new FormData();

  formData.append(FormKey.USERNAME, params[FormKey.USERNAME]);
  formData.append(FormKey.PASSWORD, params[FormKey.PASSWORD]);
  formData.append(FormKey.CSRF_TOKEN, params[FormKey.CSRF_TOKEN]);
  formData.append(FormKey.CLIENT_ID, params[FormKey.CLIENT_ID]);

  return formData;
}

export async function login(
  params: LoginParams,
  redirectUri: string,
): Promise<LoginResponse> {
  try {
    const response = await fetch(
      `${GRAVITEE_URL}/am/kameleoon/login?client_id=${
        params[FormKey.CLIENT_ID]
      }&response_type=code&redirect_uri=${redirectUri}`,
      {
        method: 'POST',
        body: getFormData(params),
      },
    );

    const url = new URL(response.url);
    const searchParams = new URLSearchParams(url.searchParams);
    const code = searchParams.get(SearchParam.CODE);
    const graviteeError = searchParams.get(SearchParam.ERROR);

    const htmlText = await response.text();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');
    const csrfToken = doc.querySelector<HTMLInputElement>(Selector.CSRF_TOKEN);
    const factorId = doc.querySelector<HTMLInputElement>(Selector.FACTOR_ID);
    const sharedSecret = doc.querySelector<HTMLInputElement>(
      Selector.SHARED_SECRET,
    );

    const error = await getError(graviteeError, params[FormKey.USERNAME]);

    return {
      error,
      code,
      csrfToken: csrfToken?.value || null,
      factorId: factorId?.value || null,
      sharedSecret: sharedSecret?.value || null,
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
