import classNames from 'classnames';
import styles from './Field.module.scss';

export function getFieldClassNames(hasError?: boolean): string {
  return classNames([styles['field'], { [styles['field_error']]: hasError }]);
}

export function getFieldControlClassNames(hasError?: boolean): string {
  return classNames([
    styles['field-control'],
    { [styles['field-control_error']]: hasError },
  ]);
}
