import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getTermsOfServiceTranslations(): {
  termService: string;
  privacyPolicy: string;
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    termService: locales['access_management.app.form.login.link.term_service'],
    privacyPolicy:
      locales['access_management.app.form.login.link.privacy_policy'],
  };
}
