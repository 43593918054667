import type { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getAppRestoreTranslations(): {
  tab: { title: string; main: string };
  title: string;
  subtitle: string;
  instruction: string;
  label: { username: string };
  button: {
    back: string;
    send: string;
  };
  success: {
    title: string;
    instruction: string;
    button: {
      success: string;
    };
  };
  error: {
    noAssociated: ReactNode;
    missingField: string;
    tooManyAttems: string;
    accountDeactivated: string;
    notYetActive: string;
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.restore.tab.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.restore.title'],
    subtitle: locales['access_management.app.form.restore.subtitle'],
    instruction: locales['access_management.app.form.restore.instruction'],
    label: {
      username: locales['access_management.app.form.label.username'],
    },
    button: {
      back: locales['access_management.app.form.button.back'],
      send: locales['access_management.app.form.button.send'],
    },
    success: {
      title: locales['access_management.app.form.restore.success.title'],
      instruction:
        locales['access_management.app.form.restore.success.instruction'],
      button: {
        success: locales['access_management.app.form.button.back_sign_in'],
      },
    },
    error: {
      noAssociated: reactStringReplace(
        locales['access_management.app.form.error.no_account'],
        '{br}',
        (_, index) => <br key={index} />,
      ),
      missingField: locales['access_management.app.form.error.missing_field'],
      tooManyAttems:
        locales['access_management.app.form.error.too_many_attems'],
      accountDeactivated:
        locales['access_management.app.form.error.account_deactivated'],
      notYetActive:
        locales['access_management.app.form.error.account_not_yet_active'],
    },
  };
}
