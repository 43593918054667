import type { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import { getNavigatorLocale } from 'core/i18n';
import { LINK_CONTACT } from 'core/constants';
import { getAppLocales } from './getAppLocales';

export function getAppLoginMfaVerifyTranslations(email: string | null): {
  tab: {
    main: string;
    title: string;
  };
  title: string;
  subtitle: string;
  content: {
    label: ReactNode;
    question: string;
    link: string;
  };
  notifications: {
    invalidCode: string;
    error5attempts: string;
    successRecentCode: string;
    warning: string;
  };
  submit: string;
  footer: {
    content: string;
    link: {
      href: string;
      text: string;
    };
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      main: locales['access_management.app.form.login.tab.main'],
      title: locales['access_management.app.form.verify.tab.title'],
    },
    title: locales['access_management.app.form.mfa.title'],
    subtitle: locales['access_management.app.form.mfa.subtitle'],
    content: {
      label: reactStringReplace(
        locales['access_management.app.form.mfa.enter_code'],
        '{email}',
        () => email,
      ),
      question: locales['access_management.app.form.mfa.question'],
      link: locales['access_management.app.form.mfa.resend_code'],
    },
    notifications: {
      invalidCode: locales['access_management.app.form.mfa.try_again'],
      error5attempts: locales['access_management.app.form.mfa.five_attempts'],
      successRecentCode:
        locales['access_management.app.form.mfa.success_notification'],
      warning: locales['access_management.app.form.mfa.warning'],
    },
    submit: locales['access_management.app.form.mfa.verify_code'],
    footer: {
      content: locales['access_management.app.form.mfa.help'],
      link: {
        href: LINK_CONTACT[locale],
        text: locales['access_management.app.form.mfa.contact_team'],
      },
    },
  };
}
