import { memo, forwardRef } from 'react';
import type { TypeSelectPayload, TypeSelectOption } from '@kameleoon/ui';
import { Select } from '@kameleoon/ui';
import classNames from 'classnames';
import { getFieldClassNames, getFieldControlClassNames } from './helpers';
import styles from './Field.module.scss';

type Props = {
  className?: string;
  classNameOpen?: string;
  classNameControl?: string;
  classNamePopover?: string;
  hasError?: boolean;
  id?: string;
  loading?: boolean;
  onChange?: (payload: TypeSelectPayload) => void;
  placeholder?: string;
  options: TypeSelectOption[];
  value?: string;
};

const SelectField = forwardRef<HTMLButtonElement, Props>(function SelectField(
  {
    className,
    classNameOpen,
    classNameControl,
    classNamePopover,
    hasError,
    id,
    loading,
    options,
    onChange,
    placeholder,
    value,
  },
  ref,
): JSX.Element {
  return (
    <Select
      className={classNames(getFieldClassNames(hasError), className)}
      classNameOpen={classNames(styles['field_open'], classNameOpen)}
      classNameControl={classNames(
        getFieldControlClassNames(hasError),
        classNameControl,
      )}
      classNamePopover={classNames(styles['field__popover'], classNamePopover)}
      disabled={loading}
      ref={ref}
      id={id}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      size="l"
      value={value}
    />
  );
});

export default memo(SelectField);
