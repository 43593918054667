import { useRef, useState, useEffect, useCallback } from 'react';
import { getAppSignUpTranslations } from 'core/i18n';
import { Login } from 'modules/Login';
import type { StateType, ErrorsType, FieldType } from './types';
import { Field, TextFieldType } from './constants';

const translations = getAppSignUpTranslations();

type UseFieldsParams = {
  loading: boolean;
};

type UseFieldsHookResult = {
  fields: FieldType[];
};

export function useFields(
  state: StateType,
  errors: ErrorsType,
  { loading }: UseFieldsParams,
): UseFieldsHookResult {
  const firstNameRef = useRef<HTMLInputElement>(null);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  useEffect(() => {
    firstNameRef.current?.focus();
  }, []);

  const handleToggleShowPassword = useCallback(() => {
    setIsPasswordShown((prev) => !prev);
  }, []);

  const fields: FieldType[] = [
    {
      autocomplete: 'given-name',
      type: TextFieldType.Text,
      ref: firstNameRef,
      label: translations.label.firstName,
      name: Field.FirstName,
      hasError: errors[Field.FirstName],
      value: state[Field.FirstName],
    },
    {
      autocomplete: 'family-name',
      type: TextFieldType.Text,
      label: translations.label.lastName,
      name: Field.LastName,
      hasError: errors[Field.LastName],
      value: state[Field.LastName],
    },
    {
      autocomplete: 'email',
      type: TextFieldType.Text,
      label: translations.label.email,
      name: Field.Email,
      hasError: errors[Field.Email],
      value: state[Field.Email],
    },
    {
      autocomplete: 'organization',
      type: TextFieldType.Text,
      label: translations.label.company,
      name: Field.Company,
      hasError: errors[Field.Company],
      value: state[Field.Company],
    },
    {
      autocomplete: 'url',
      type: TextFieldType.Text,
      label: translations.label.companyUrl,
      name: Field.CompanyUrl,
      hasError: errors[Field.CompanyUrl],
      value: state[Field.CompanyUrl],
    },
    {
      autocomplete: 'new-password',
      type: isPasswordShown ? TextFieldType.Text : TextFieldType.Password,
      label: translations.label.password,
      tooltip: (
        <Login.Container type="tooltip">
          <Login.Tooltip
            ariaLabel={translations.aria.password.tooltip}
            content={translations.tooltip.password}
          />
        </Login.Container>
      ),
      name: Field.Password,
      hasError: errors[Field.Password],
      value: state[Field.Password],
      addonAfter: loading ? undefined : (
        <Login.PasswordToogler
          isShown={isPasswordShown}
          onClick={handleToggleShowPassword}
          aria={translations.aria.password}
        />
      ),
    },
  ];

  return {
    fields,
  };
}
