export enum Field {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Company = 'name',
  CompanyUrl = 'projectUrl',
  Password = 'password',
  TermsOfService = 'TERMS_OF_SERVICE',
  ProjectType = 'projectType',
  Country = 'country',
  CustomerIntegrationTools = 'customerIntegrationTools',
  ForceBasicCdn = 'forceBasicCdn',
  EnableMfa = 'enableMfa',
  ContractualMonthlyTraffic = 'contractualMonthlyTraffic',
  RawExportPlan = 'rawExportPlan',
}

export const TextFieldType = {
  Text: 'text',
  Password: 'password',
} as const;

export const EARLY_ADOPTER_60_DAYS = 'early-adopter-60-days';
