import { useState, useCallback } from 'react';

export type TypeErrorHookResult = {
  hasError: boolean;
  error?: string;
  setError: (error?: string) => void;
};

export function useError(): TypeErrorHookResult {
  const [error, setError] = useState<string | undefined>(undefined);

  const update = useCallback((value?: string) => {
    setError(value);
  }, []);

  return {
    hasError: typeof error !== 'undefined',
    error,
    setError: update,
  };
}
