import { getNavigatorLocale, getAppSignUpTranslations } from 'core/i18n';
import { Page, paths } from 'core/routes';
import { LINK_DEMO } from 'core/constants';
import type { FooterItemType } from 'modules/Login/Footer/types';

const locale = getNavigatorLocale();
const translations = getAppSignUpTranslations();

export function useFooter(): FooterItemType[] {
  return [
    {
      message: translations.footer.account.message,
      link: {
        external: false,
        href: paths[Page.APP_LOGIN],
        text: translations.footer.account.link,
      },
    },
    {
      message: translations.footer.demo.message,
      link: {
        href: LINK_DEMO[locale],
        text: translations.footer.demo.link,
      },
    },
  ];
}
