export enum User {
  CUSTOMER = 'customer',
  USERNAME = 'username',
  PASSWORD = 'password',
  REMEMBER_ME = 'rememberMe',
}

export enum ErrorPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
