import { useCallback, useState } from 'react';
import { resendVerificationEmail } from './resendVerificationEmail';

type TypeLoginHookResult = {
  loading: boolean;
  resendEmail: (email: string) => Promise<boolean>;
};

export function useResendVerificationEmail(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const resendEmail = useCallback(async (email: string) => {
    setLoading(true);

    try {
      const response = await resendVerificationEmail(email);

      return response;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    resendEmail,
  };
}
