export enum Page {
  APP_LOGIN = 'APP_LOGIN',
  APP_LOGIN_SSO_AUTHORIZE = 'APP_LOGIN_SSO_AUTHORIZE',
  APP_LOGIN_SSO_GOOGLE_INTERNAL = 'APP_LOGIN_SSO_GOOGLE_INTERNAL',
  APP_LOGIN_SSO_GOOGLE_AUTHORIZE = 'APP_LOGIN_SSO_GOOGLE_AUTHORIZE',
  APP_LOGIN_SSO_ERROR = 'APP_LOGIN_SSO_ERROR',
  APP_LOGIN_SSO_GOOGLE_ERROR = 'APP_LOGIN_SSO_GOOGLE_ERROR',
  APP_LOGIN_MFA_VERIFY = 'APP_LOGIN_MFA_VERIFY',
  APP_RESTORE = 'APP_RESTORE',
  APP_RESET_PASSWORD = 'APP_RESET_PASSWORD',
  APP_ADMIN = 'APP_ADMIN',
  APP_ADMIN_SSO = 'APP_ADMIN_SSO',
  APP_ADMIN_SSO_INTERNAL = 'APP_ADMIN_SSO_INTERNAL',
  APP_ADMIN_SSO_AUTHORIZE = 'APP_ADMIN_SSO_AUTHORIZE',
  APP_ADMIN_SSO_ERROR = 'APP_ADMIN_SSO_ERROR',
  APP_EDITOR = 'APP_EDITOR',
  APP_SIGN_UP = 'APP_SIGN_UP',
  APP_SIGN_UP_VERIFY = 'APP_SIGN_UP_VERIFY',
  APP_SIGN_UP_VERIFIED = 'APP_SIGN_UP_VERIFIED',
  APP_FREE_TRIAL_ERROR = 'APP_FREE_TRIAL_ERROR',
  ADMIN_LOGIN = 'ADMIN_LOGIN',
  ADMIN_SSO = 'ADMIN_LOGIN_SSO',
  ADMIN_SSO_INTERNAL = 'ADMIN_SSO_INTERNAL',
  ADMIN_SSO_AUTHORIZE = 'ADMIN_SSO_AUTHORIZE',
  ADMIN_SSO_ERROR = 'ADMIN_SSO_ERROR',
  ACADEMY_LOGIN = 'ACADEMY_LOGIN',
}

export const paths: Record<Page, string> = {
  [Page.APP_LOGIN]: '/app',
  [Page.APP_LOGIN_SSO_AUTHORIZE]: '/app/sso/authorize',
  [Page.APP_LOGIN_SSO_GOOGLE_INTERNAL]: '/sso/google',
  [Page.APP_LOGIN_SSO_GOOGLE_AUTHORIZE]: '/app/sso/google/authorize',
  [Page.APP_LOGIN_SSO_ERROR]: '/app/sso/error',
  [Page.APP_LOGIN_SSO_GOOGLE_ERROR]: '/app/sso/google/error',
  [Page.APP_LOGIN_MFA_VERIFY]: '/app/mfa/verify',
  [Page.APP_RESTORE]: '/app/restore',
  [Page.APP_RESET_PASSWORD]: '/app/restore/password',
  [Page.APP_ADMIN]: '/app/admin',
  [Page.APP_ADMIN_SSO]: '/app/admin/sso',
  [Page.APP_ADMIN_SSO_INTERNAL]: '/app/admin/sso/internal',
  [Page.APP_ADMIN_SSO_AUTHORIZE]: '/app/admin/sso/authorize',
  [Page.APP_ADMIN_SSO_ERROR]: '/app/admin/sso/error',
  [Page.APP_EDITOR]: '/app/editor',
  [Page.APP_SIGN_UP]: '/app/sign-up',
  [Page.APP_SIGN_UP_VERIFY]: '/app/sign-up/verify',
  [Page.APP_SIGN_UP_VERIFIED]: '/app/sign-up/verified',
  [Page.APP_FREE_TRIAL_ERROR]: '/app/free-trial/error',
  [Page.ADMIN_LOGIN]: '/admin',
  [Page.ADMIN_SSO]: '/admin/sso',
  [Page.ADMIN_SSO_INTERNAL]: '/admin/sso/internal',
  [Page.ADMIN_SSO_AUTHORIZE]: '/admin/sso/authorize',
  [Page.ADMIN_SSO_ERROR]: '/admin/sso/error',
  [Page.ACADEMY_LOGIN]: '/academy',
};

export const APP_ADMIN_SSO_REDIRECT_KEY = 'APP_ADMIN_SSO_REDIRECT_KEY';

export enum AppPage {
  FEATURE_FLAG_DASHBOARD = 'FEATURE_FLAG_DASHBOARD',
}

export const appPaths: Record<AppPage, string> = {
  [AppPage.FEATURE_FLAG_DASHBOARD]: '/featureFlags/dashboard',
};
