import { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { paths, Page } from 'core/routes';
import { getAppLoginSSOErrorTranslations } from 'core/i18n';
import { TermsOfService } from 'modules/TermsOfService';
import { SSOErrorPage } from 'modules/SSOErrorPage';

function AppLoginSSOError(): JSX.Element {
  const { push } = useHistory();
  const translations = getAppLoginSSOErrorTranslations();

  const handleSubmit = useCallback(() => {
    push(paths[Page.APP_LOGIN]);
  }, [push]);

  return (
    <SSOErrorPage
      addonFooter={<TermsOfService />}
      onSubmit={handleSubmit}
      translations={translations}
    />
  );
}

export default memo(AppLoginSSOError);
