import { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { paths, Page } from 'core/routes';
import { getAdminSSOErrorTranslations } from 'core/i18n';
import { SSOErrorPage } from 'modules/SSOErrorPage';

function AdminSSOError(): JSX.Element {
  const { push } = useHistory();
  const translations = getAdminSSOErrorTranslations();

  const handleSubmit = useCallback(() => {
    push(paths[Page.ADMIN_SSO]);
  }, [push]);

  return <SSOErrorPage onSubmit={handleSubmit} translations={translations} />;
}

export default memo(AdminSSOError);
