import { REST_URL } from 'core/env';

export enum EmailError {
  EmailExistsPremiumUser = 'EMAIL_ALREADY_EXISTS_PREMIUM_USER',
  EmailExists = 'EMAIL_ALREADY_EXISTS',
  EmailIsFake = 'EMAIL_IS_FAKE',
}
type Params = {
  email: string;
};

export async function checkEmailValidity({
  email,
}: Params): Promise<{ valid: boolean; error?: EmailError }> {
  try {
    const response = await fetch(
      `${REST_URL}/accounts/checkEmailValidity?email=${encodeURIComponent(
        email,
      )}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 200) {
      return {
        valid: true,
      };
    }

    const result = await response.json();

    return {
      valid: false,
      error: result.error,
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
