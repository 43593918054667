import { useContext } from 'react';
import type { MixpanelContextType } from './types';
import { MixpanelContext } from './MixpanelContext';

export function useMixpanel(): MixpanelContextType {
  const context = useContext(MixpanelContext);

  if (typeof context === 'undefined') {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }

  return context;
}
