import { REST_URL } from 'core/env';

export async function getAuthSSOError(): Promise<boolean> {
  try {
    const response = await fetch(
      `${REST_URL}/accounts/authenticationSsoError`,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    if (response.status === 401 || response.status === 403) {
      return true;
    }

    return false;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(JSON.stringify(error));
    return true;
  }
}
