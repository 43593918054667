import type { FormEvent } from 'react';
import { useState, useRef, useEffect, useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { LinkButton } from '@kameleoon/ui';
import { getAppRestoreTranslations } from 'core/i18n';
import { Errors } from 'core/constants';
import { Page, paths } from 'core/routes';
import { Login } from 'modules/Login';
import { User } from 'modules/Login/constants';
import { Title } from 'modules/Title';
import { useRestore } from 'core/api';
import backgroundUrl from 'assets/images/app-background.svg';
import styles from './AppRestore.module.scss';

const translations = getAppRestoreTranslations();

function AppRestore(): JSX.Element {
  const history = useHistory();
  const { loading, restore } = useRestore();
  const [success, setSuccess] = useState<boolean>(false);
  const usernameRef = useRef<HTMLInputElement>(null);
  const {
    user,
    error,
    hasError,
    setUser: handleChangeUser,
    setError,
  } = Login.useUser();

  useEffect(() => {
    usernameRef.current?.focus();
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (user.username === '') {
        setError(Errors.MISSING_FIELDS);
        usernameRef.current?.focus();
        return;
      }

      const response = await restore(user.username);

      if (response.error) {
        setError(response.error);
      } else {
        setSuccess(true);
      }
    },
    [restore, setError, user],
  );

  const handleSubmitSuccess = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      history.push(paths[Page.APP_LOGIN]);
    },
    [history],
  );

  const getErrorMessage = useCallback(() => {
    switch (error) {
      case Errors.MISSING_FIELDS:
        return [
          {
            key: Errors.MISSING_FIELDS,
            value: translations.error.missingField,
          },
        ];
      case Errors.TOO_MANY_ATTEMPTS:
        return [
          {
            key: Errors.TOO_MANY_ATTEMPTS,
            value: translations.error.tooManyAttems,
          },
        ];
      case Errors.NOT_YET_ACTIVE:
        return [
          {
            key: Errors.NOT_YET_ACTIVE,
            value: translations.error.notYetActive,
          },
        ];
      case Errors.USER_NOT_FOUND:
        return [
          {
            key: Errors.USER_NOT_FOUND,
            value: translations.error.noAssociated,
          },
        ];
      case Errors.ACCOUNT_DEACTIVATED:
        return [
          {
            key: Errors.ACCOUNT_DEACTIVATED,
            value: translations.error.accountDeactivated,
          },
        ];
      default:
        return [];
    }
  }, [error]);

  if (success) {
    return (
      <Login.Page
        backgroundUrl={backgroundUrl}
        addonRightSection={<Login.Rocket />}
      >
        <Login.FormSuccess
          onSubmit={handleSubmitSuccess}
          translations={translations.success}
        />
      </Login.Page>
    );
  }

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <div className={styles['form__subtitle']}>
          <Login.Subtitle>{translations.subtitle}</Login.Subtitle>
        </div>
        <div className={styles['form__instruction']}>
          <h3 className={styles['instruction']}>{translations.instruction}</h3>
        </div>
        <div className={styles['form__username']}>
          <Login.Label>{translations.label.username}</Login.Label>
          <Login.Field
            id={User.USERNAME}
            autocomplete="username"
            hasError={hasError}
            loading={loading}
            name={User.USERNAME}
            onChange={handleChangeUser}
            ref={usernameRef}
            value={user[User.USERNAME]}
          />
        </div>
        <Login.Errors
          className={styles['form__errors']}
          messages={getErrorMessage()}
        />
        <div className={styles['controls']}>
          <div className={styles['controls__item']}>
            <LinkButton
              className={styles['button-cancel']}
              href={paths[Page.APP_LOGIN]}
              size="l"
              theme="white"
            >
              {translations.button.back}
            </LinkButton>
          </div>
          <div className={styles['controls__item']}>
            <Login.ButtonSubmit
              loading={loading}
              label={translations.button.send}
            />
          </div>
        </div>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AppRestore);
