import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSSOAuthorize } from 'core/api';
import { ADMIN_REDIRECT_URL, GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';

function AdminSSOAuthorize(): null {
  const { push } = useHistory();

  useSSOAuthorize({
    redirectUri: `${GRAVITEE_URL}${paths[Page.ADMIN_SSO_AUTHORIZE]}`,
    rememberMe: true,
    callback: ({ hasAuthSSOError }) => {
      if (hasAuthSSOError) {
        push(paths[Page.ADMIN_SSO_ERROR]);
      } else {
        window.location.href = ADMIN_REDIRECT_URL;
      }
    },
    catchCallback: () => {
      push(paths[Page.ADMIN_SSO]);
    },
  });

  return null;
}

export default memo(AdminSSOAuthorize);
