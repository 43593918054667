import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Container.module.scss';

type Props = {
  type:
    | 'title'
    | 'subtitle'
    | 'tooltip'
    | 'content'
    | 'username'
    | 'password'
    | 'controls';
  children?: ReactNode;
  className?: string;
};

function Container({ type, children, className }: Props): JSX.Element {
  return <div className={classNames(styles[type], className)}>{children}</div>;
}

export default memo(Container);
