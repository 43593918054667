import { Errors } from 'core/constants';
import { getAuthError } from './getAuthError';

export async function getError(
  graviteeError: string | null,
  username: string,
): Promise<Errors | null> {
  if (graviteeError === null) {
    return null;
  }

  const { error } = await getAuthError(username);

  if (error !== Errors.UNDEFINED_ERROR) {
    return error;
  }

  return graviteeError as Errors;
}
