import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Title.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
};

function Title({ children, className }: Props): JSX.Element {
  return <h1 className={classNames(styles['title'], className)}>{children}</h1>;
}

export default memo(Title);
