import type { ReactNode } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Label.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  htmlFor?: string;
};

function Label({ children, className, htmlFor }: Props): JSX.Element {
  return (
    <label className={classNames(styles['label'], className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

export default memo(Label);
