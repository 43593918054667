import type { ChangeEvent } from 'react';
import { useState, useCallback } from 'react';
import type { TypeUser } from './types';
import type { TypeErrorHookResult } from './useError';
import { useError } from './useError';
import { User } from './constants';

type UseUserParams = {
  [User.CUSTOMER]?: string;
  [User.USERNAME]?: string;
  [User.REMEMBER_ME]?: boolean;
};

export type TypeUpdateUserEvent = ChangeEvent<HTMLInputElement>;

export type TypeUseUserHookResult = {
  user: TypeUser;
  setUser: (event: TypeUpdateUserEvent) => void;
} & TypeErrorHookResult;

export function useUser(params?: UseUserParams): TypeUseUserHookResult {
  const { customer = '', username = '', rememberMe = false } = params || {};

  const [user, setUser] = useState<TypeUser>({
    [User.CUSTOMER]: customer,
    [User.USERNAME]: username,
    [User.PASSWORD]: '',
    [User.REMEMBER_ME]: rememberMe,
  });
  const { hasError, error, setError } = useError();

  const autoResetError = useCallback(
    (prevUser: TypeUser, name: User, value: string) => {
      if (name !== User.REMEMBER_ME && prevUser[name] !== value) {
        setError(undefined);
      }
    },
    [setError],
  );

  const update = useCallback(
    (event: TypeUpdateUserEvent) => {
      const { name, value, checked } = event.target;

      setUser((prevUser) => {
        autoResetError(prevUser, name as User, value);

        return {
          ...prevUser,
          [name]: name === User.REMEMBER_ME ? checked : value,
        };
      });
    },
    [autoResetError],
  );

  return {
    user,
    setUser: update,
    hasError,
    error,
    setError,
  };
}
