import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

type TypeLoginSSOFormTranslations = {
  tab: {
    title: string;
    main: string;
  };
  title: string;
  subtitle: string;
  label: {
    customer: string;
  };
  error: {
    loginFailed: string;
  };
  button: {
    login: string;
  };
};

export function getAppAdminSSOTranslations(): TypeLoginSSOFormTranslations {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.login.admin_sso.tab.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.admin.title'],
    subtitle: locales['access_management.app.form.admin.subtitle'],
    label: {
      customer:
        locales['access_management.app.form.admin.label.username.customer'],
    },
    error: {
      loginFailed:
        locales['access_management.app.form.admin_sso.error.missing_field'],
    },
    button: {
      login: locales['access_management.app.form.button.log_in_sso'],
    },
  };
}
