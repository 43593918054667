import { Locale } from './schema';

export const LINK_DEMO = {
  [Locale.EN]: 'https://pages.master.preview.kameleoon.net/en/demo',
  [Locale.FR]: 'https://pages.master.preview.kameleoon.net/fr/demo',
  [Locale.DE]: 'https://pages.master.preview.kameleoon.net/de/demo',
} as const;

export const LINK_TERMS_SERVICE = {
  [Locale.EN]: 'https://master.preview.kameleoon.net/en/legal-notice',
  [Locale.FR]: 'https://master.preview.kameleoon.net/fr/mentions-legales',
  [Locale.DE]: 'https://master.preview.kameleoon.net/de/impressum',
} as const;

export const LINK_PRIVACY_POLICY = {
  [Locale.EN]: 'https://master.preview.kameleoon.net/en/privacy-policy',
  [Locale.FR]: 'https://master.preview.kameleoon.net/fr/politique-confidentialite',
  [Locale.DE]: 'https://master.preview.kameleoon.net/de/datenschutz',
} as const;

export const LINK_CONTACT = {
  [Locale.EN]: 'https://www.master.preview.kameleoon.net/en/contact',
  [Locale.FR]: 'https://www.master.preview.kameleoon.net/fr/contact',
  [Locale.DE]: 'https://www.master.preview.kameleoon.net/de/kontakt',
};

export const LINK_ACADEMY = 'https://academy.master.preview.kameleoon.net/auth/login/callback';
