import { memo } from 'react';
import classNames from 'classnames';
import { Link } from 'modules/Login/Link';
import styles from './LegalLinks.module.scss';

type LegalLink = {
  href: string;
  text: string;
};

type Props = {
  className?: string;
  items: LegalLink[];
};

function LegalLinks({ className, items }: Props): JSX.Element {
  return (
    <div className={classNames(styles['legal'], className)}>
      {items.map((item, index) => (
        <>
          <Link
            className={styles['link']}
            href={item.href}
            external
            target="_blank"
          >
            {item.text}
          </Link>
          {items.length !== index + 1 && (
            <span className={styles['separator']}>|</span>
          )}
        </>
      ))}
    </div>
  );
}

export default memo(LegalLinks);
