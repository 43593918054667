import type { ReactNode } from 'react';
import { getNavigatorLocale } from 'core/i18n';
import reactStringReplace from 'react-string-replace';
import { getAppLocales } from './getAppLocales';

export function getAppResetPasswordTranslations(): {
  tab: { title: string; main: string };
  title: string;
  subtitle: string;
  label: { password: string; passwordConfirm: string };
  aria: {
    password: {
      hide: string;
      show: string;
      tooltip: string;
    };
  };
  tooltip: {
    password: ReactNode;
  };
  button: { reset: string };
  success: {
    title: string;
    button: {
      success: string;
    };
  };
  error: {
    required: string;
    noMatch: string;
    unexpected: string;
    regExp: string;
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.reset_password.tab.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.reset_password.title'],
    subtitle: locales['access_management.app.form.reset_password.subtitle'],
    label: {
      password:
        locales['access_management.app.form.reset_password.label.new_password'],
      passwordConfirm:
        locales[
          'access_management.app.form.reset_password.label.new_password_confirm'
        ],
    },
    aria: {
      password: {
        hide: locales['access_management.app.form.aria.label.hide_password'],
        show: locales['access_management.app.form.aria.label.show_password'],
        tooltip: locales['access_management.app.form.aria.label.password_info'],
      },
    },
    tooltip: {
      password: reactStringReplace(
        locales['access_management.app.form.tooltip.password'],
        '{br}',
        (_, index) => <br key={index} />,
      ),
    },
    button: {
      reset: locales['access_management.app.form.button.reset'],
    },
    success: {
      title: locales['access_management.app.form.reset_password.success.title'],
      button: {
        success: locales['access_management.app.form.button.back_sign_in'],
      },
    },
    error: {
      required: locales['access_management.app.form.error.missing_fields'],
      noMatch:
        locales['access_management.app.form.reset_password.error.no_match'],
      unexpected:
        locales['access_management.app.form.reset_password.error.unexpected'],
      regExp:
        locales['access_management.app.form.reset_password.error.reg_exp'],
    },
  };
}
