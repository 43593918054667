export enum Locale {
  EN = 'en',
  FR = 'fr',
  DE = 'de',
}

export enum Country {
  France = 'FRANCE',
  Germany = 'GERMANY',
  Other = 'OTHER',
  Russia = 'RUSSIA',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES',
}

export enum ProjectType {
  Application = 'APPLICATION',
  Site = 'SITE',
  SiteJs = 'SITE_JS',
  SiteSdk = 'SITE_SDK',
}

export enum RawExportPlan {
  Free = 'FREE',
  Tier_2 = 'TIER_2',
  Tier_3 = 'TIER_3',
}
