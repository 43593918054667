import { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getNavigatorLocale } from 'core/i18n';
import { getRoutes } from 'core/routes';

function App(): JSX.Element {
  useEffect(() => {
    document.documentElement.setAttribute('lang', getNavigatorLocale());
  }, []);

  return (
    <Router>
      <Switch>
        {getRoutes().map(({ type, path, exact, component }) => (
          <Route key={type} path={path} exact={exact}>
            {component}
          </Route>
        ))}
      </Switch>
    </Router>
  );
}

export default App;
