export function getPasswordPattern(): string {
  const ONE_LOWERCASE_CHARACTER_REGEXP = `(?=.*[a-z])`;
  const ONE_UPPERCASE_CHARACTER_REGEXP = `(?=.*[A-Z])`;
  const ONE_DIGIT_REGEXP = `(?=.*[0-9])`;
  const ONE_SPECIAL_CHARACTER = `(?=.*['\\*\`()|[{\\]}_@:;#$%^&+=.<>,!?~"-])`;
  const ANY_CHARACTER_REGEXP = `.`;
  const MIN_TEN_CHARACTER_REGEXP = `{10,}`;

  return `^${ONE_LOWERCASE_CHARACTER_REGEXP}${ONE_UPPERCASE_CHARACTER_REGEXP}${ONE_DIGIT_REGEXP}${ONE_SPECIAL_CHARACTER}${ANY_CHARACTER_REGEXP}${MIN_TEN_CHARACTER_REGEXP}$`;
}

export function isPasswordValid(password: string): boolean {
  return !!password.match(new RegExp(getPasswordPattern()));
}
