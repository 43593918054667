import { useCallback, useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import type { EmailError } from 'core/api';
import { checkEmailValidity } from 'core/api';
import { isPasswordValid } from 'core/helpers';
import { Country, ProjectType, RawExportPlan } from 'core/constants';
import { Field } from './constants';
import type { ErrorStatusType, ErrorsType, StateType } from './types';
import { ErrorStatus } from './types';

type UseFormHookResult = {
  state: StateType;
  errors: ErrorsType;
  errorStatus: ErrorStatusType;
  setState: (name: string, value: string | boolean) => void;
  setErrorStatus: (status: ErrorStatusType) => void;
  validate: () => Promise<boolean>;
};

export function useForm(): UseFormHookResult {
  const [state, setState] = useState<StateType>({
    [Field.FirstName]: '',
    [Field.LastName]: '',
    [Field.Email]: '',
    [Field.Company]: '',
    [Field.CompanyUrl]: '',
    [Field.Password]: '',
    [Field.TermsOfService]: false,
    [Field.ProjectType]: ProjectType.SiteSdk,
    [Field.Country]: getCountry(),
    [Field.CustomerIntegrationTools]: [],
    [Field.ForceBasicCdn]: false,
    [Field.EnableMfa]: false,
    [Field.ContractualMonthlyTraffic]: 0,
    [Field.RawExportPlan]: RawExportPlan.Free,
  });
  const [errors, setErrors] = useState<ErrorsType>({
    [Field.FirstName]: false,
    [Field.LastName]: false,
    [Field.Email]: false,
    [Field.Company]: false,
    [Field.CompanyUrl]: false,
    [Field.Password]: false,
  });
  const [errorStatus, setErrorStatus] = useState<ErrorStatusType>(
    ErrorStatus.Valid,
  );

  useEffect(() => {
    const hasNotErrors = Object.values(errors).every((item) => item === false);

    if (hasNotErrors) {
      setErrorStatus(ErrorStatus.Valid);
    }
  }, [errors]);

  const setStateHandler = useCallback(
    (name: string, value: string | boolean) => {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));

      if (name !== Field.TermsOfService) {
        setErrors((prev) => ({
          ...prev,
          [name]: false,
        }));

        if (
          errors[name as keyof ErrorsType] ||
          errorStatus === ErrorStatus.SomethingWentWrong
        ) {
          setErrorStatus(ErrorStatus.Valid);
        }
      }
    },
    [errors, errorStatus],
  );

  const getError = useCallback(async () => {
    const email = state[Field.Email];
    const url = state[Field.CompanyUrl];
    const password = state[Field.Password];
    const currentErrors: ErrorsType = {
      [Field.FirstName]: state[Field.FirstName] === '',
      [Field.LastName]: state[Field.LastName] === '',
      [Field.Email]: email === '' || !isEmail(email),
      [Field.Company]: state[Field.Company] === '',
      [Field.CompanyUrl]: url === '' || !isURL(url),
      [Field.Password]: password === '' || !isPasswordValid(password),
    };

    let errorCount = Object.values(currentErrors).filter(Boolean).length;
    let emailError: EmailError | undefined;

    if (!currentErrors[Field.Email]) {
      try {
        const { valid, error } = await checkEmailValidity({
          email: state[Field.Email],
        });

        if (!valid) {
          errorCount += 1;
          currentErrors[Field.Email] = true;
          emailError = error;
        }
      } catch (error) {
        setErrorStatus(ErrorStatus.SomethingWentWrong);
      }
    }

    return {
      currentErrors,
      errorCount,
      emailError,
    };
  }, [state]);

  const validate = useCallback(async () => {
    const { currentErrors, errorCount, emailError } = await getError();

    if (errorCount === 0) {
      return true;
    }

    setErrors((prev) => ({ ...prev, ...currentErrors }));

    if (emailError !== undefined) {
      setErrorStatus(emailError);
    } else if (
      currentErrors[Field.Email] ||
      currentErrors[Field.CompanyUrl] ||
      currentErrors[Field.Password]
    ) {
      setErrorStatus(ErrorStatus.SeveralFields);
    } else if (errorCount === 1) {
      setErrorStatus(ErrorStatus.OneField);
    }

    return false;
  }, [getError]);

  return {
    state,
    errors,
    errorStatus,
    setState: setStateHandler,
    setErrorStatus,
    validate,
  };
}

function getCountry(): Country {
  switch (window.navigator.language) {
    case 'fr-FR':
      return Country.France;
    case 'de-DE':
      return Country.Germany;
    case 'ru-RU':
      return Country.Russia;
    case 'en-US':
      return Country.UnitedStates;
    case 'en-GB':
    case 'gd-GB':
      return Country.UnitedKingdom;
    default:
      return Country.Other;
  }
}
