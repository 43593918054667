import { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { paths, Page } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { getAppLoginSSOErrorGoogleTranslations } from 'core/i18n';
import { useAcademyLogin } from 'core/api';
import { TermsOfService } from 'modules/TermsOfService';
import { SSOErrorPage } from 'modules/SSOErrorPage';
import { EventName } from './mixpanel';

function AppLoginSSOErrorGoogle(): JSX.Element {
  const { push } = useHistory();
  const { track } = useMixpanel();
  const translations = getAppLoginSSOErrorGoogleTranslations();
  const { getParamsAcademy, redirectToAcademyLogin } = useAcademyLogin();

  const handleSubmit = useCallback(() => {
    track({
      eventName: EventName.CLICKED_ON_BUTTON_TRY_ANOTHER_WAY,
    });

    if (getParamsAcademy() !== null) {
      redirectToAcademyLogin();
      return;
    }

    push(paths[Page.APP_LOGIN]);
  }, [track, getParamsAcademy, push, redirectToAcademyLogin]);

  return (
    <SSOErrorPage
      addonFooter={<TermsOfService />}
      onSubmit={handleSubmit}
      translations={translations}
    />
  );
}

export default memo(AppLoginSSOErrorGoogle);
