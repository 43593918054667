import { memo } from 'react';
import { Icon } from '@kameleoon/ui';
import { getAppSignUpRightSectionTranslations } from 'core/i18n';
import featureFlags from 'assets/images/sign-up-feature-flags.png';
import { paths, Page } from 'core/routes/constants';
import { checkIcon } from './checkIcon';
import { VARIATIONS } from './variations';
import styles from './RightSection.module.scss';

const translations = getAppSignUpRightSectionTranslations();

function RightSection(): JSX.Element {
  return (
    <section className={styles['section']}>
      <div className={styles['section__title']}>
        <h1 className={styles['title']}>
          {translations.title.part1}
          <br />
          {translations.title.part2}
        </h1>
      </div>
      {!window.location.href.includes(paths[Page.APP_SIGN_UP_VERIFIED]) && (
        <div className={styles['section__subtitle']}>
          <h2 className={styles['subtitle']}>{translations.subtitle}</h2>
        </div>
      )}
      <ul className={styles['list']}>
        {translations.items.map((item) => (
          <li className={styles['item']} key={item}>
            <Icon
              className={styles['item__icon']}
              path={checkIcon.path}
              viewBox={checkIcon.viewBox}
            />
            <span className={styles['item__text']}>{item}</span>
          </li>
        ))}
      </ul>
      <div className={styles['feature-flags']}>
        <img src={featureFlags} alt="feature-flags" />
      </div>
      <div className={styles['variations']}>
        {VARIATIONS.map((item) => (
          <div className={styles['variations__item']} key={item.key}>
            <img src={item.image} alt={item.key} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default memo(RightSection);
