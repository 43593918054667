import variation1 from 'assets/images/sign-up-variation-1.svg';
import variation2 from 'assets/images/sign-up-variation-2.svg';
import variation3 from 'assets/images/sign-up-variation-3.svg';

export const VARIATIONS = [
  {
    key: 'variation1',
    image: variation1,
  },
  {
    key: 'variation2',
    image: variation2,
  },
  {
    key: 'variation3',
    image: variation3,
  },
];
