import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSSOAuthorize } from 'core/api';
import { APP_REDIRECT_URL, GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';

function AppLoginSSOAuthorize(): null {
  const { push } = useHistory();

  useSSOAuthorize({
    redirectUri: `${GRAVITEE_URL}${paths[Page.APP_LOGIN_SSO_AUTHORIZE]}`,
    rememberMe: true,
    callback: ({ hasAuthSSOError }) => {
      if (hasAuthSSOError) {
        push(paths[Page.APP_LOGIN_SSO_ERROR]);
      } else {
        window.location.href = APP_REDIRECT_URL;
      }
    },
    catchCallback: () => {
      push(paths[Page.APP_LOGIN]);
    },
  });

  return null;
}

export default memo(AppLoginSSOAuthorize);
