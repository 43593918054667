import { Errors } from 'core/constants';
import { REST_URL } from 'core/env';

const GONE = 410;
const TOO_MANY_REQUESTS = 429;
const UNAUTHORIZED = 401;

function getError(response: Response, text: string): Errors | null {
  switch (response.status) {
    case GONE:
      return Errors.USER_NOT_FOUND;
    case TOO_MANY_REQUESTS:
      return Errors.TOO_MANY_ATTEMPTS;
    case UNAUTHORIZED:
      if (text.includes(Errors.ACCOUNT_DEACTIVATED)) {
        return Errors.ACCOUNT_DEACTIVATED;
      }
      return Errors.NOT_YET_ACTIVE;
    default:
      return null;
  }
}

export async function restore(
  username: string,
): Promise<{ error: Errors | null }> {
  try {
    const response = await fetch(
      `${REST_URL}/accounts/password/reset?username=${encodeURIComponent(
        username,
      )}`,
      { method: 'POST' },
    );
    const responseText = await response.text();

    return {
      error: getError(response, responseText),
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
