import {
  getSsoErrorCodesTranslations,
  getSsoErrorTranslations,
} from 'core/i18n';
import type { SSOErrorPageTranslationsType } from 'modules/SSOErrorPage';
import { getAdminSSOTranslations } from './getAdminSSOTranslations';

export function getAdminSSOErrorTranslations(): SSOErrorPageTranslationsType {
  const locales = getAdminSSOTranslations();
  const ssoCodes = getSsoErrorCodesTranslations();
  const ssoError = getSsoErrorTranslations({
    tabMain: locales.tab.main,
    error: ssoCodes.loginFailed,
  });

  return ssoError;
}
