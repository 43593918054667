import type { RefObject, ReactNode } from 'react';
import type { EmailError } from 'core/api';
import type { ProjectType, RawExportPlan } from 'core/constants';
import type { Field, TextFieldType } from './constants';

export type FieldType = {
  autocomplete: string;
  type: typeof TextFieldType.Text | typeof TextFieldType.Password;
  ref?: RefObject<HTMLInputElement>;
  label: string;
  tooltip?: ReactNode;
  name: string;
  hasError: boolean;
  value: string;
  addonAfter?: ReactNode;
};

export type StateType = Omit<
  Record<Field, string>,
  | Field.TermsOfService
  | Field.ProjectType
  | Field.CustomerIntegrationTools
  | Field.ForceBasicCdn
  | Field.EnableMfa
  | Field.ContractualMonthlyTraffic
  | Field.RawExportPlan
> & {
  [Field.TermsOfService]: boolean;
  [Field.ProjectType]: ProjectType;
  [Field.CustomerIntegrationTools]: [];
  [Field.ForceBasicCdn]: boolean;
  [Field.EnableMfa]: boolean;
  [Field.ContractualMonthlyTraffic]: number;
  [Field.RawExportPlan]: RawExportPlan;
};

export type ErrorsType = Omit<
  Record<Field, boolean>,
  | Field.TermsOfService
  | Field.ProjectType
  | Field.CustomerIntegrationTools
  | Field.Country
  | Field.ForceBasicCdn
  | Field.EnableMfa
  | Field.ContractualMonthlyTraffic
  | Field.RawExportPlan
>;

export enum ErrorStatus {
  Valid = 'VALID',
  OneField = 'ONE_FIELD',
  SeveralFields = 'SEVERAL_FIELDS',
  SomethingWentWrong = 'SOMETHING_WENT_WRONG',
}

export type ErrorStatusType = ErrorStatus | EmailError;
