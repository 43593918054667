import type { LoginFormTemplateRef } from 'modules/Login';
import { useCallback, useEffect, useRef, memo } from 'react';
import { getAdminLoginTranslations, getLoginErrorMessage } from 'core/i18n';
import { Errors } from 'core/constants';
import { useLogin } from 'core/api';
import { Page, useRedirectAfterLogin } from 'core/routes';
import { ADMIN_REDIRECT_URL } from 'core/env';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import backgroundUrl from 'assets/images/app-background.svg';

const translations = getAdminLoginTranslations();
const redirectUri = ADMIN_REDIRECT_URL;

function AdminLogin(): JSX.Element {
  const redirectAfterLogin = useRedirectAfterLogin({
    page: Page.ADMIN_LOGIN,
    redirectUri,
  });
  const { loading, login } = useLogin({
    redirectUri,
    callback: () => {
      window.location.href = redirectAfterLogin;
    },
  });
  const usernameRef = useRef<LoginFormTemplateRef>(null);
  const {
    user,
    error,
    hasError,
    setUser: handleChangeUser,
    setError,
  } = Login.useUser();

  useEffect(() => {
    usernameRef.current?.username.focus();
  }, []);

  const handleSubmit = useCallback(async () => {
    if (user.username === '' || user.password === '') {
      setError(Errors.MISSING_FIELDS);
      usernameRef.current?.username.focus();
      return;
    }

    const { error: loginError } = await login({
      ...user,
      rememberMe: true,
    });

    if (loginError) {
      setError(loginError);
    }
  }, [login, setError, user]);

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.FormTemplate
        user={user}
        error={getLoginErrorMessage(translations.error, error)}
        loading={loading}
        onChangeUser={handleChangeUser}
        onSubmit={handleSubmit}
        hasError={hasError}
        ref={usernameRef}
        translations={translations}
      />
    </Login.Page>
  );
}

export default memo(AdminLogin);
