import { useEffect } from 'react';
import { appPaths, AppPage } from 'core/routes';
import { APP_REDIRECT_URL } from 'core/env';

export function useLoggedInRedirect(): void {
  useEffect(() => {
    if (document.cookie.includes('expiration-access')) {
      window.location.href = `${APP_REDIRECT_URL}${
        appPaths[AppPage.FEATURE_FLAG_DASHBOARD]
      }`;
    }
  }, []);
}
