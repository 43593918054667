import { FormKey, SearchParam, Selector } from 'core/constants';
import { GRAVITEE_URL } from 'core/env';
import { getInput } from 'core/helpers';
import { getError } from './getError';
import type { LoginResponse } from './login';

type Params = {
  [FormKey.USERNAME]: string;
  [FormKey.CSRF_TOKEN]: string;
  [FormKey.SHARED_SECRET]: string;
  [FormKey.FACTOR_ID]: string;
};

function getFormData(params: Params): FormData {
  const formData = new FormData();

  formData.append(FormKey.EMAIL_FACT, params[FormKey.USERNAME]);
  formData.append(FormKey.SHARED_SECRET, params[FormKey.SHARED_SECRET]);
  formData.append(FormKey.CSRF_TOKEN, params[FormKey.CSRF_TOKEN]);
  formData.append(FormKey.FACTOR_ID, params[FormKey.FACTOR_ID]);

  return formData;
}

export async function loginEnroll(
  params: Params,
  redirectUri: string,
): Promise<Omit<LoginResponse, 'factorId' | 'sharedSecret'>> {
  const clientId = getInput(Selector.CLIENT_ID).value;
  const response = await fetch(
    `${GRAVITEE_URL}/am/kameleoon/mfa/enroll?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`,
    {
      method: 'POST',
      body: getFormData(params),
    },
  );

  const url = new URL(response.url);
  const searchParams = new URLSearchParams(url.searchParams);
  const htmlText = await response.text();
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlText, 'text/html');

  const code = searchParams.get(SearchParam.CODE);
  const csrfToken = doc.querySelector<HTMLInputElement>(Selector.CSRF_TOKEN);
  const graviteeError = searchParams.get(SearchParam.ERROR);
  const error = await getError(graviteeError, params[FormKey.USERNAME]);

  return {
    error,
    code,
    csrfToken: csrfToken?.value || null,
  };
}
