import { useCallback, useEffect, useRef, useState } from 'react';
import { resendMfaCode } from 'core/api';
import { INITIAL_SECONDS, Selector, TIMER_STEP } from 'core/constants';
import { getInput } from 'core/helpers';
import { useMixpanel } from 'core/mixpanel';
import { EventName } from 'projects/app/AppLoginMfaVerify/mixpanel';

export function useResendMfaCode(redirectUri: string): {
  attempts: number;
  timer: number;
  inscreaseAttempts: () => void;
  resend: () => Promise<boolean>;
} {
  const { track } = useMixpanel();
  const [timer, setTimer] = useState<number>(0);
  const [attempts, setAttempts] = useState<number>(0);
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timer > 0) {
      interval.current = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            if (interval.current) {
              clearInterval(interval.current);
            }
          }
          return prevTimer - 1;
        });
      }, TIMER_STEP);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [timer]);

  async function resend(): Promise<boolean> {
    const { csrfToken, hasError } = await resendMfaCode(redirectUri);

    getInput(Selector.CSRF_TOKEN).value = csrfToken;

    if (!hasError) {
      setTimer(INITIAL_SECONDS);
      track({
        eventName: EventName.CLICKED_ON_RESEND_VERIFICATION_CODE,
      });
    }

    return hasError;
  }

  const inscreaseAttempts = useCallback(() => {
    setAttempts((prev) => prev + 1);
  }, []);

  return {
    attempts,
    inscreaseAttempts,
    timer,
    resend,
  };
}
