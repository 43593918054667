import { getNavigatorLocale } from 'core/i18n';
import { getSsoErrorLocales } from './getSsoErrorLocales';

type SsoErrorCodesTranslationsType = {
  notMatchKameleoonAccount: string;
  loginFailed: string;
};

export function getSsoErrorCodesTranslations(): SsoErrorCodesTranslationsType {
  const locale = getNavigatorLocale();
  const locales = getSsoErrorLocales(locale);

  return {
    notMatchKameleoonAccount:
      locales['access_management.sso_error.not_match_kameleoon_account'],
    loginFailed: locales['access_management.sso_error.login_failed'],
  };
}
