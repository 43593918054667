import type { ReactNode } from 'react';
import { getNavigatorLocale } from 'core/i18n';
import { LINK_CONTACT } from 'core/constants';
import type { ModalUpgradeTranslationsType } from 'projects/app/AppFreeTrialError/ModalUpgrade/types';
import { getAppLocales } from './getAppLocales';

export function getAppFreeTrialExpiredTranslations(): {
  tab: {
    main: string;
  };
  title: string;
  subtitle: ReactNode;
  content: {
    beforeControls: ReactNode;
    afterControls: {
      text: string;
      link: {
        href: string;
        text: string;
      };
    };
  };
  button: {
    upgrade: string;
    discover: string;
  };
  modal: ModalUpgradeTranslationsType;
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.free_trial_expired.title'],
    subtitle: locales['access_management.app.form.free_trial_expired.subtitle'],
    content: {
      beforeControls:
        locales[
          'access_management.app.form.free_trial_expired.content.before_controls'
        ],
      afterControls: {
        text: locales[
          'access_management.app.form.free_trial_expired.content.after_controls.text'
        ],
        link: {
          href: LINK_CONTACT[locale],
          text: locales[
            'access_management.app.form.free_trial_expired.content.after_controls.link'
          ],
        },
      },
    },
    button: {
      upgrade:
        locales['access_management.app.form.free_trial_expired.button.upgrade'],
      discover:
        locales[
          'access_management.app.form.free_trial_expired.button.discover'
        ],
    },
    modal: {
      title:
        locales['access_management.app.form.free_trial_expired.popup.upgrade'],
      message: {
        step1:
          locales[
            'access_management.app.form.free_trial_expired.popup.descripton'
          ],
        step2: {
          message1:
            locales[
              'access_management.app.form.free_trial_expired.notification.success'
            ],
          message2:
            locales[
              'access_management.app.form.free_trial_expired.notification.get_back'
            ],
        },
      },
      button: {
        cancel:
          locales[
            'access_management.app.form.free_trial_expired.popup.button_cancel'
          ],
        confirm:
          locales[
            'access_management.app.form.free_trial_expired.popup.button_confirm'
          ],
        ok: locales['access_management.app.ok'],
      },
      error:
        locales[
          'access_management.app.form.sign_up.error.something_went_wrong'
        ],
    },
  };
}
