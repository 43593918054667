import type { ChangeEvent, FormEvent } from 'react';
import { useCallback, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TextField, Checkbox } from '@kameleoon/ui';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import { RightSection } from 'modules/SignUp';
import { getAppSignUpTranslations } from 'core/i18n';
import { useSignUp } from 'core/api';
import { paths, Page } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import backgroundUrl from 'assets/images/sign-up-background.svg';
import { useForm } from './useForm';
import { useErrorsMessages } from './useErrorsMessages';
import { useFields } from './useFields';
import { useFooter } from './useFooter';
import { useLoggedInRedirect } from './useLoggedInRedirect';
import { EventName } from './mixpanel';
import { Field, EARLY_ADOPTER_60_DAYS } from './constants';
import { ErrorStatus } from './types';
import styles from './AppSignUp.module.scss';

const translations = getAppSignUpTranslations();

function AppSignUp(): JSX.Element {
  const { loading, createAccount } = useSignUp();
  const { state, errors, errorStatus, setState, setErrorStatus, validate } =
    useForm();
  const { fields } = useFields(state, errors, { loading });
  const { track } = useMixpanel();
  const { push } = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { promocode } = useParams<{ promocode?: string }>();

  useLoggedInRedirect();

  const parsePromocode = useCallback(() => {
    if (
      promocode?.includes(EARLY_ADOPTER_60_DAYS) ||
      window.location.search.includes(EARLY_ADOPTER_60_DAYS)
    ) {
      return EARLY_ADOPTER_60_DAYS;
    }

    return undefined;
  }, [promocode]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (errorStatus !== ErrorStatus.Valid) {
        setErrorStatus(ErrorStatus.Valid);
      }

      const isValid = await validate();

      if (isValid) {
        try {
          if (!executeRecaptcha) {
            throw new Error('Execute recaptcha not yet available!');
          }

          const recaptchaResponseToken = await executeRecaptcha(
            'CREATE_FREE_TRIAL_CUSTOMER',
          );
          const { TERMS_OF_SERVICE, ...params } = state;
          const success = await createAccount({
            ...params,
            recaptchaResponseToken,
            promocode: parsePromocode(),
          });

          if (success) {
            track({
              eventName: EventName.CLICKED_ON_BUTTON_SIGN_UP,
              properties: {
                FIRST_NAME: params.firstName,
                LAST_NAME: params.lastName,
                WORK_EMAIL: params.email,
                COMPANY: params.name,
                COMPANY_URL: params.projectUrl,
                PROJECT_TYPE: params.projectType,
                PROMOCODE: parsePromocode(),
              },
            });

            push(
              `${paths[Page.APP_SIGN_UP_VERIFY]}?email=${encodeURIComponent(
                params.email,
              )}`,
            );
          } else {
            throw new Error('The free trial customer has not been created!');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
          setErrorStatus(ErrorStatus.SomethingWentWrong);
        }
      }
    },
    [
      state,
      errorStatus,
      parsePromocode,
      createAccount,
      executeRecaptcha,
      push,
      setErrorStatus,
      track,
      validate,
    ],
  );

  const handleChangeTextField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setState(event.target.name, event.target.value);
    },
    [setState],
  );

  const handleChangeTermsOfService = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setState(event.target.name, event.target.checked);
    },
    [setState],
  );

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<RightSection />}
      addonFooter={<Login.Footer items={useFooter()} />}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container className={styles['subtitle']} type="subtitle">
          <Login.Subtitle className={styles['subtitle__text']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <div className={styles['fields']}>
          {fields.map((field) => (
            <div key={field.name} className={styles['container']}>
              <Login.Label htmlFor={field.name}>
                {field.label}
                {field.tooltip}
              </Login.Label>
              <TextField
                size="l"
                autocomplete={field.autocomplete}
                type={field.type}
                ref={field.ref}
                id={field.name}
                name={field.name}
                hasError={field.hasError}
                value={field.value}
                onChange={handleChangeTextField}
                addonAfter={field.addonAfter}
              />
            </div>
          ))}
        </div>
        <div className={styles['errors']}>
          <Login.Errors
            className={styles['errors__messages']}
            position={Login.ErrorPosition.Left}
            messages={useErrorsMessages(errorStatus)}
          />
        </div>
        <Login.Container type="controls">
          <div className={styles['terms-of-service']}>
            <Checkbox
              className={styles['terms-of-service__label']}
              classNameLabel={styles['terms-of-service__text']}
              theme="green"
              label={translations.checkbox.agree}
              name={Field.TermsOfService}
              onChange={handleChangeTermsOfService}
            />
          </div>
          <Login.ButtonSubmit
            label={translations.button.signUp}
            loading={loading}
            disabled={!state[Field.TermsOfService]}
          />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AppSignUp);
