import { REST_URL } from 'core/env';

export async function verifyAccount(
  id: string,
  token: string,
): Promise<boolean> {
  try {
    const response = await fetch(`${REST_URL}/accounts/${id}/verify`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.status === 200;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
