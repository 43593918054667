import { useState, useCallback } from 'react';
import type { Errors } from 'core/constants';
import { restore as appRestore } from './restore';

type TypeLoginHookResult = {
  loading: boolean;
  restore: (username: string) => Promise<{ error: Errors | null }>;
};

export function useRestore(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const restore = useCallback(async (username: string) => {
    setLoading(true);

    try {
      const { error } = await appRestore(String(username).trim());

      return {
        error,
      };
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    restore,
  };
}
