import type { FormEvent } from 'react';
import { memo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { paths, Page, useSearchParamValue } from 'core/routes';
import { useMixpanel } from 'core/mixpanel';
import { Login } from 'modules/Login';
import { RightSection } from 'modules/SignUp';
import { TermsOfService } from 'modules/TermsOfService';
import backgroundUrl from 'assets/images/sign-up-background.svg';
import verifiedUrl from 'assets/images/sign-up-verified.svg';
import { Title } from 'modules/Title';
import { getAppConfirmationTranslations } from 'core/i18n/app/getAppConfirmationTranslations';
import { SearchParam } from 'core/constants';
import { useSignUpVerifyAccount } from 'core/api';
import styles from './AppSignUpVerified.module.scss';
import { EventName } from './mixpanel';

const translations = getAppConfirmationTranslations();

function AppSignUpVerified(): JSX.Element {
  const history = useHistory();
  const { search } = useLocation();
  const { push } = useHistory();
  const { track } = useMixpanel();

  const token = useSearchParamValue(SearchParam.TOKEN);

  const { verify, loading } = useSignUpVerifyAccount();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    queryParams.delete(SearchParam.TOKEN);
    history.replace({ search: queryParams.toString() });

    if (token) {
      verify(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      track({
        eventName: EventName.CLICKED_ON_BUTTON_LOG_IN,
      });
      push(paths[Page.APP_LOGIN]);
    },
    [track, push],
  );

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<RightSection />}
      addonFooter={<TermsOfService />}
    >
      <Title>{`${translations.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="content">
          <img
            src={verifiedUrl}
            className={styles['image']}
            alt="sign-up-verified"
          />
        </Login.Container>
        <Login.Container type="title">
          <Login.Title className={styles['title']}>
            {translations.title}
          </Login.Title>
        </Login.Container>
        <Login.Container type="subtitle">
          <Login.Subtitle className={styles['subtitle']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <Login.Container type="controls">
          <Login.ButtonSubmit
            loading={loading}
            label={translations.button.logIn}
          />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(AppSignUpVerified);
