import { getNavigatorLocale } from 'core/i18n';
import reactStringReplace from 'react-string-replace';
import type { TypeLoginPageTranslations } from 'modules/Login';
import { getAppLocales } from './getAppLocales';

export function getAppLoginTranslations(): TypeLoginPageTranslations {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.login.tab.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.login.title'],
    subtitle: locales['access_management.app.form.login.subtitle'],
    label: {
      username: locales['access_management.app.form.label.username'],
      password: locales['access_management.app.form.label.password'],
    },
    button: {
      login: locales['access_management.app.form.button.log_in'],
      continueWithGoogle:
        locales['access_management.app.form.button.continue_with_google'],
    },
    or: locales['access_management.app.or'],
    tooltip: {
      password: reactStringReplace(
        locales['access_management.app.form.tooltip.password'],
        '{br}',
        (_, index) => <br key={index} />,
      ),
    },
    aria: {
      password: {
        hide: locales['access_management.app.form.aria.label.hide_password'],
        show: locales['access_management.app.form.aria.label.show_password'],
        tooltip: locales['access_management.app.form.aria.label.password_info'],
      },
    },
    link: {
      forgottenPassword:
        locales['access_management.app.form.login.link.forgot_password'],
    },
    checkbox: {
      rememberMe:
        locales['access_management.app.form.login.checkbox.remember_me'],
    },
    error: {
      loginFailed:
        locales['access_management.app.form.error.username_password'],
      sessionExpired:
        locales['access_management.app.form.error.session_expired'],
      maximalPasswordAttempts:
        locales['access_management.app.form.error.maximal_password_attempts'],
      temporarilyBlocked:
        locales['access_management.app.form.error.temporarily_blocked'],
      pleaseReset: locales['access_management.app.form.error.please_reset'],
      accountDeactivated:
        locales['access_management.app.form.error.account_deactivated'],
      unauthorizedIpAddress:
        locales['access_management.app.form.error.unauthorized_ip_address'],
      contantAdmin: locales['access_management.app.form.error.contant_admin'],
    },
  };
}
