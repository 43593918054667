import en from 'locales/admin.en.json';
import fr from 'locales/admin.fr.json';
import de from 'locales/admin.de.json';
import { Locale } from 'core/constants';

export function getAdminLocales(locale: Locale): typeof en {
  switch (locale) {
    default:
    case Locale.EN:
      return en;
    case Locale.FR:
      return fr;
    case Locale.DE:
      return de;
  }
}
