import { forwardRef, memo } from 'react';
import { Button, Loader } from '@kameleoon/ui';
import classNames from 'classnames';
import styles from './ButtonSubmit.module.scss';

type Props = {
  loading?: boolean;
  disabled?: boolean;
  label: string;
};

const SubmitButton = forwardRef<HTMLButtonElement, Props>(function SubmitButton(
  { loading, disabled, label },
  ref,
): JSX.Element {
  return (
    <Button
      className={classNames([
        styles['submit'],
        { [styles['submit_loading']]: loading },
      ])}
      disabled={disabled || loading}
      size="l"
      theme="blue"
      type="submit"
      ref={ref}
    >
      {loading ? <Loader className={styles['loader']} /> : label}
    </Button>
  );
});

export default memo(SubmitButton);
