export const PUBLIC_URL = (() => {
  if (!process.env.PUBLIC_URL) {
    throw new Error('process.env.PUBLIC_URL is required!');
  }

  return process.env.PUBLIC_URL;
})();

export const REST_URL = (() => {
  if (!process.env.REACT_APP_REST_URL) {
    throw new Error('process.env.REACT_APP_REST_URL is required!');
  }

  return process.env.REACT_APP_REST_URL;
})();

export const GRAVITEE_URL = (() => {
  if (!process.env.REACT_APP_GRAVITEE_URL) {
    throw new Error('process.env.REACT_APP_GRAVITEE_URL is required!');
  }

  return process.env.REACT_APP_GRAVITEE_URL;
})();

export const APP_REDIRECT_URL = (() => {
  if (!process.env.REACT_APP_GRAVITEE_APP_REDIRECT_URL) {
    throw new Error(
      'process.env.REACT_APP_GRAVITEE_APP_REDIRECT_URL is required!',
    );
  }

  return process.env.REACT_APP_GRAVITEE_APP_REDIRECT_URL;
})();

export const HAS_DEPLOY_INFO = (() => {
  return process.env.REACT_APP_HAS_DEPLOY_INFO === 'true';
})();

export const ADMIN_REDIRECT_URL = (() => {
  if (!process.env.REACT_APP_GRAVITEE_ADMIN_REDIRECT_URL) {
    throw new Error(
      'process.env.REACT_APP_GRAVITEE_ADMIN_REDIRECT_URL is required!',
    );
  }

  return process.env.REACT_APP_GRAVITEE_ADMIN_REDIRECT_URL;
})();

export const MIXPANEL_TOKEN = (() => {
  return process.env.REACT_APP_MIXPANEL_TOKEN;
})();
