/* eslint-disable react/jsx-props-no-spreading */
import type { ReactNode, MouseEvent, KeyboardEvent } from 'react';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFocus } from '@kameleoon/hooks';
import classNames from 'classnames';
import styles from './Link.module.scss';

const TARGET = {
  Self: '_self',
  Blank: '_blank',
  Parent: '_parent',
  Top: '_top',
} as const;

type Props = {
  children: ReactNode;
  className?: string;
  external?: boolean;
  target?:
    | typeof TARGET.Self
    | typeof TARGET.Blank
    | typeof TARGET.Parent
    | typeof TARGET.Top;
  rel?: string;
  href: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLAnchorElement>) => void;
};

function LinkDemo({
  children,
  className,
  external,
  target,
  rel,
  href,
  onClick,
  onKeyDown,
}: Props): JSX.Element {
  const [ref, focused] = useFocus<HTMLAnchorElement>('keyboard');

  function getRel(): string | undefined {
    if (rel) {
      return rel;
    }
    if (target === TARGET.Blank) {
      return 'noopener noreferrer';
    }
    return undefined;
  }

  const linkProps = {
    className: classNames(
      styles['link'],
      { [styles['link_focus']]: focused },
      className,
    ),
    onClick,
    onKeyDown,
    rel: getRel(),
    ref,
    target,
  };

  if (external) {
    return (
      <a {...linkProps} href={href}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink {...linkProps} to={href}>
      {children}
    </RouterLink>
  );
}

export default memo(LinkDemo);
