import type { ChangeEvent, FormEvent } from 'react';
import { useState, useCallback, useEffect, useRef, memo } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { getAppResetPasswordTranslations } from 'core/i18n';
import { useResetPassword } from 'core/api';
import { Errors, SearchParam } from 'core/constants';
import { Page, paths, useSearchParamValue } from 'core/routes';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import backgroundUrl from 'assets/images/app-background.svg';
import styles from './AppResetPassword.module.scss';

const translations = getAppResetPasswordTranslations();

enum Passwords {
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
}

const AUTOCOMPLETE = 'new-password';
const PASSWORD_REGEXP = /^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/;

function AppResetPassword(): JSX.Element {
  const history = useHistory();
  const { search } = useLocation();
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const [passwords, setPasswords] = useState({
    [Passwords.PASSWORD]: '',
    [Passwords.PASSWORD_CONFIRM]: '',
  });
  const [error, setError] = useState<Errors | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const token = useSearchParamValue(SearchParam.TOKEN);
  const accountId = useSearchParamValue(SearchParam.ACCOUNT_ID);

  const { reset, loading } = useResetPassword();

  useEffect(() => {
    passwordRef.current?.focus();
  }, []);

  const removeSearchParam = useCallback(() => {
    const queryParams = new URLSearchParams(search);

    queryParams.delete(SearchParam.TOKEN);
    queryParams.delete(SearchParam.ACCOUNT_ID);
    history.replace({ search: queryParams.toString() });
  }, [history, search]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const password = passwords[Passwords.PASSWORD];
      const passwordConfirm = passwords[Passwords.PASSWORD_CONFIRM];

      if (password === '') {
        setError(Errors.MISSING_FIELDS);
        passwordRef.current?.focus();
        return;
      }

      if (passwordConfirm === '') {
        setError(Errors.MISSING_FIELDS);
        passwordConfirmRef.current?.focus();
        return;
      }

      if (!PASSWORD_REGEXP.test(password)) {
        setError(Errors.PASSWORD_REGEXP);
        passwordRef.current?.focus();
        return;
      }

      if (password !== passwordConfirm) {
        setError(Errors.NO_MATCH);
        passwordRef.current?.focus();
        return;
      }

      if (token && accountId) {
        const { error: resetError } = await reset({
          token,
          accountId,
          password,
          passwordConfirm,
        });

        if (resetError) {
          setError(resetError);
        } else {
          setSuccess(true);
          removeSearchParam();
        }
      }
    },
    [passwords, token, accountId, reset, removeSearchParam],
  );

  const handleSubmitSuccess = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      history.push(paths[Page.APP_LOGIN]);
    },
    [history],
  );

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
    setError(null);
  }, []);

  const getErrorMessage = useCallback(() => {
    switch (error) {
      case Errors.MISSING_FIELDS:
        return [
          {
            key: Errors.MISSING_FIELDS,
            value: translations.error.required,
          },
        ];
      case Errors.NO_MATCH:
        return [
          {
            key: Errors.NO_MATCH,
            value: translations.error.noMatch,
          },
        ];
      case Errors.PASSWORD_REGEXP:
        return [
          {
            key: Errors.PASSWORD_REGEXP,
            value: translations.error.regExp,
          },
        ];
      case Errors.UNEXPECTED:
        return [
          {
            key: Errors.UNEXPECTED,
            value: translations.error.unexpected,
          },
        ];
      default:
        return [];
    }
  }, [error]);

  if ((token && accountId) || success) {
    if (success) {
      return (
        <Login.Page
          backgroundUrl={backgroundUrl}
          addonRightSection={<Login.Rocket />}
        >
          <Login.FormSuccess
            onSubmit={handleSubmitSuccess}
            translations={translations.success}
          />
        </Login.Page>
      );
    }

    return (
      <Login.Page
        backgroundUrl={backgroundUrl}
        addonRightSection={<Login.Rocket />}
      >
        <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
        <Login.Form onSubmit={handleSubmit}>
          <Login.Container type="title">
            <Login.Title>{translations.title}</Login.Title>
          </Login.Container>
          <Login.Container type="subtitle">
            <Login.Subtitle>{translations.subtitle}</Login.Subtitle>
          </Login.Container>
          <Login.Container type="username">
            <Login.Label htmlFor={Passwords.PASSWORD}>
              {translations.label.password}
              <Login.Container type="tooltip">
                <Login.Tooltip
                  ariaLabel={translations.aria.password.tooltip}
                  content={translations.tooltip.password}
                />
              </Login.Container>
            </Login.Label>
            <Login.Password
              aria={translations.aria.password}
              autocomplete={AUTOCOMPLETE}
              id={Passwords.PASSWORD}
              hasError={Boolean(error)}
              loading={loading}
              name={Passwords.PASSWORD}
              onChange={handleChange}
              ref={passwordRef}
              value={passwords[Passwords.PASSWORD]}
            />
          </Login.Container>
          <Login.Container type="password">
            <Login.Label htmlFor={Passwords.PASSWORD_CONFIRM}>
              {translations.label.passwordConfirm}
            </Login.Label>
            <Login.Password
              aria={translations.aria.password}
              autocomplete={AUTOCOMPLETE}
              id={Passwords.PASSWORD_CONFIRM}
              hasError={Boolean(error)}
              loading={loading}
              name={Passwords.PASSWORD_CONFIRM}
              onChange={handleChange}
              ref={passwordConfirmRef}
              value={passwords[Passwords.PASSWORD_CONFIRM]}
            />
          </Login.Container>
          <Login.Errors
            className={styles['errors']}
            messages={getErrorMessage()}
          />
          <Login.ButtonSubmit
            loading={loading}
            label={translations.button.reset}
          />
        </Login.Form>
      </Login.Page>
    );
  }

  return <Redirect to={paths[Page.APP_LOGIN]} />;
}

export default memo(AppResetPassword);
