import { useCallback, useState } from 'react';
import { updatePlan } from './updatePlan';

type TypeLoginHookResult = {
  loading: boolean;
  updatedPlan: (email: string) => Promise<boolean>;
};

export function useUpdatePlan(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const updatedPlan = useCallback(async (email: string) => {
    setLoading(true);

    try {
      const response = await updatePlan(email);

      return response;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    updatedPlan,
  };
}
