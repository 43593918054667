import type { ReactNode } from 'react';
import { memo } from 'react';
import { Icon, Scrollbar } from '@kameleoon/ui';
import { kameleoonIcon } from '@kameleoon/icons';
import styles from './Page.module.scss';

type Props = {
  children: ReactNode;
  backgroundUrl: string;
  addonRightSection?: ReactNode;
  addonFooter?: ReactNode;
};

function Page({
  children,
  backgroundUrl,
  addonRightSection,
  addonFooter,
}: Props): JSX.Element {
  return (
    <div className={styles['page']}>
      <div className={styles['form-section']}>
        <Scrollbar theme="page">
          <div className={styles['form-section__scroll-container']}>
            <div className={styles['form-section__header']}>
              <Icon
                className={styles['logo']}
                path={kameleoonIcon.path}
                viewBox={kameleoonIcon.viewBox}
                title="Kameleoon"
              />
            </div>
            <div className={styles['form-section__content']}>
              <div className={styles['form-section__form']}>{children}</div>
            </div>
            {typeof addonFooter !== 'undefined' && (
              <div className={styles['form-section__footer']}>
                {addonFooter}
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
      <div className={styles['right-section']}>
        <div
          style={{ backgroundImage: `url(${backgroundUrl})` }}
          className={styles['background-picture']}
        >
          {addonRightSection}
        </div>
      </div>
    </div>
  );
}

export default memo(Page);
