import { memo } from 'react';
import { Login } from 'modules/Login';
import { TermsOfService } from 'modules/TermsOfService';
import type { FooterItemType } from './types';
import styles from './Footer.module.scss';

type Props = {
  items: FooterItemType[];
};

function Footer({ items }: Props): JSX.Element {
  return (
    <div className={styles['footer']}>
      <div className={styles['footer__links']}>
        {items.map((item) => (
          <Login.DemoLink
            className={styles['footer__link']}
            message={item.message}
            link={item.link}
          />
        ))}
      </div>
      <TermsOfService />
    </div>
  );
}

export default memo(Footer);
