import type { ReactNode } from 'react';
import { memo } from 'react';
import { Tooltip, Icon } from '@kameleoon/ui';
import { helpIcon } from '@kameleoon/icons';
import styles from './Tooltip.module.scss';

type Props = {
  content: ReactNode;
  ariaLabel: string;
};

function TooltipWrapper({ content, ariaLabel }: Props): JSX.Element {
  return (
    <Tooltip
      classNamePopover={styles['tooltip']}
      position="bottom-start"
      offset={[0, 15]}
      content={content}
    >
      <div className={styles['icon']} aria-label={ariaLabel}>
        <Icon
          className={styles['icon__svg']}
          path={helpIcon.path}
          viewBox={helpIcon.viewBox}
        />
      </div>
    </Tooltip>
  );
}

export default memo(TooltipWrapper);
