import { getNavigatorLocale } from 'core/i18n';
import { getAdminLocales } from './getAdminLocales';

type AdminSSOTranslationsType = {
  tab: {
    title: string;
    main: string;
  };
  title: string;
  subtitle: string;
  button: {
    login: string;
  };
};

export function getAdminSSOTranslations(): AdminSSOTranslationsType {
  const locale = getNavigatorLocale();
  const locales = getAdminLocales(locale);

  return {
    tab: {
      title: locales['access_management.admin.form.login.sso.tab.title'],
      main: locales['access_management.admin.form.login.tab.main'],
    },
    title: locales['access_management.admin.form.login.title'],
    subtitle: locales['access_management.admin.form.admin.subtitle'],
    button: {
      login: locales['access_management.admin.form.button.log_in_sso'],
    },
  };
}
