import type { FormEvent } from 'react';
import { memo } from 'react';
import { Title } from '../Title';
import { ButtonSubmit } from '../ButtonSubmit';
import styles from './FormSuccess.module.scss';

type Props = {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  translations: {
    title: string;
    instruction?: string;
    button: {
      success: string;
    };
  };
};

function FormSuccess({ onSubmit, translations }: Props): JSX.Element {
  return (
    <form className={styles['form-success']} onSubmit={onSubmit}>
      <div className={styles['form-success__title']}>
        <Title>{translations.title}</Title>
      </div>
      {translations.instruction && (
        <div className={styles['form-success__instruction']}>
          <h3 className={styles['instruction']}>{translations.instruction}</h3>
        </div>
      )}
      <ButtonSubmit label={translations.button.success} />
    </form>
  );
}

export default memo(FormSuccess);
