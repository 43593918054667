import { getAppLoginTranslations, getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from 'core/i18n/app/getAppLocales';
import reactStringReplace from 'react-string-replace';
import type { TypeLoginPageTranslations } from 'modules/Login';

export function getAcademyLoginTranslations(): TypeLoginPageTranslations {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);
  const appTranslations = getAppLoginTranslations();

  return {
    ...appTranslations,
    tab: {
      ...appTranslations.tab,
      title: locales['access_management.app.form.login.tab.academy_title'],
    },
    title: reactStringReplace(
      locales['access_management.app.form.login.academy_title'],
      '{br}',
      (_, index) => <br key={index} />,
    ),
    subtitle: locales['access_management.app.form.login.academy_subtitle'],
  };
}
