import {
  Page,
  useRedirectAfterLogin,
  APP_ADMIN_SSO_REDIRECT_KEY,
} from 'core/routes';
import { SearchParam } from 'core/constants';
import { APP_REDIRECT_URL } from 'core/env';

const redirectUri = APP_REDIRECT_URL;

export function useRedirectAfterSSOLogin(): string {
  const redirectAfterLogin = useRedirectAfterLogin({
    page: Page.APP_ADMIN,
    redirectUri,
  });

  const url = new URL(redirectAfterLogin);
  const redirectAfterSSOLogin = sessionStorage.getItem(
    APP_ADMIN_SSO_REDIRECT_KEY,
  );

  if (redirectAfterSSOLogin) {
    url.searchParams.set(SearchParam.REDIRECT, redirectAfterSSOLogin);
  }

  return url.toString();
}
