import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSSOAuthorize } from 'core/api';
import { GRAVITEE_URL } from 'core/env';
import { paths, Page } from 'core/routes';
import { useRedirectAfterSSOLogin } from './useRedirectAfterSSOLogin';

function AppAdminSSOAuthorize(): null {
  const { push } = useHistory();
  const redirectAfterLogin = useRedirectAfterSSOLogin();

  useSSOAuthorize({
    redirectUri: `${GRAVITEE_URL}${paths[Page.APP_ADMIN_SSO_AUTHORIZE]}`,
    rememberMe: true,
    callback: ({ hasAuthSSOError }) => {
      if (hasAuthSSOError) {
        push(paths[Page.APP_ADMIN_SSO_ERROR]);
      } else {
        window.location.href = redirectAfterLogin;
      }
    },
    catchCallback: () => {
      push(paths[Page.APP_ADMIN_SSO]);
    },
  });

  return null;
}

export default memo(AppAdminSSOAuthorize);
