import { getNavigatorLocale } from 'core/i18n';
import { getAppLocales } from './getAppLocales';

export function getAppLoginFooterTranslations(): {
  account: {
    message: string;
    link: string;
  };
  freeTrial: {
    message: string;
    link: string;
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    account: {
      message:
        locales['access_management.app.form.login.footer.account.message'],
      link: locales['access_management.app.form.login.footer.account.link'],
    },
    freeTrial: {
      message:
        locales['access_management.app.form.login.footer.free_trial.message'],
      link: locales['access_management.app.form.login.footer.free_trial.link'],
    },
  };
}
