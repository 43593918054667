import { REST_URL } from 'core/env';

export async function resendVerificationEmail(email: string): Promise<boolean> {
  try {
    const response = await fetch(
      `${REST_URL}/accounts/resendVerificationEmail?emailAddress=${encodeURIComponent(
        email,
      )}`,
      {
        method: 'GET',
      },
    );

    return response.status === 200;
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
