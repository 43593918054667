import { useState, useCallback } from 'react';
import type { Errors } from 'core/constants';
import type { ResetPasswordParams } from './resetPassword';
import { resetPassword } from './resetPassword';

type TypeLoginHookResult = {
  loading: boolean;
  reset: (params: ResetPasswordParams) => Promise<{ error: Errors | null }>;
};

export function useResetPassword(): TypeLoginHookResult {
  const [loading, setLoading] = useState(false);

  const reset = useCallback(async (params: ResetPasswordParams) => {
    setLoading(true);

    try {
      const { error } = await resetPassword(params);

      return {
        error,
      };
    } catch (error) {
      throw new Error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    reset,
  };
}
