import type { ReactNode, FormEvent } from 'react';
import { useCallback, memo } from 'react';
import { Login } from 'modules/Login';
import { Title } from 'modules/Title';
import backgroundUrl from 'assets/images/app-background.svg';
import type { SSOErrorPageTranslationsType } from './types';
import { Padlock } from './Padlock';
import styles from './SSOErrorPage.module.scss';

type Props = {
  addonFooter?: ReactNode;
  loading?: boolean;
  onSubmit: () => void;
  translations: SSOErrorPageTranslationsType;
};

function SSOErrorPage({
  addonFooter,
  loading = false,
  onSubmit,
  translations,
}: Props): JSX.Element {
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <Login.Page
      backgroundUrl={backgroundUrl}
      addonRightSection={<Login.Rocket />}
      addonFooter={addonFooter}
    >
      <Title>{`${translations.tab.title} - ${translations.tab.main}`}</Title>
      <Login.Form onSubmit={handleSubmit}>
        <Login.Container type="title">
          <Login.Title>{translations.title}</Login.Title>
        </Login.Container>
        <Login.Container type="content">
          <Login.Subtitle className={styles['subtitle']}>
            {translations.subtitle}
          </Login.Subtitle>
        </Login.Container>
        <Login.Container type="content" className={styles['padlock']}>
          <Padlock />
        </Login.Container>
        <Login.Container type="controls">
          <Login.ButtonSubmit loading={loading} label={translations.submit} />
        </Login.Container>
      </Login.Form>
    </Login.Page>
  );
}

export default memo(SSOErrorPage);
