import { memo } from 'react';
import { Icon } from '@kameleoon/ui';
import { padlockIcon } from './padlockIcon';
import styles from './Padlock.module.scss';

function Padlock(): JSX.Element {
  return (
    <div className={styles['padlock']}>
      <Icon
        className={styles['padlock__icon']}
        path={padlockIcon.path}
        viewBox={padlockIcon.viewBox}
      />
    </div>
  );
}

export default memo(Padlock);
