import type { ReactNode } from 'react';
import { getNavigatorLocale } from 'core/i18n';
import reactStringReplace from 'react-string-replace';
import { Link } from 'modules/Login/Link';
import { LINK_TERMS_SERVICE } from 'core/constants';
import styles from './getAppSignUpTranslations.module.scss';
import { getAppLocales } from '../getAppLocales';

export function getAppSignUpTranslations(): {
  tab: {
    title: string;
    main: string;
  };
  title: string;
  subtitle: string;
  label: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    companyUrl: string;
    password: string;
  };
  checkbox: {
    agree: ReactNode;
  };
  button: {
    signUp: string;
  };
  tooltip: {
    password: ReactNode;
  };
  aria: {
    password: {
      hide: string;
      show: string;
      tooltip: string;
    };
  };
  footer: {
    account: {
      message: string;
      link: string;
    };
    demo: {
      message: string;
      link: string;
    };
  };
  error: {
    field: {
      one: string;
      several: string;
    };
    email: {
      premium: string;
      freeTrial: string;
      blacklisted: string;
    };
    somethingWentWrong: string;
  };
} {
  const locale = getNavigatorLocale();
  const locales = getAppLocales(locale);

  return {
    tab: {
      title: locales['access_management.app.form.sign_up.title'],
      main: locales['access_management.app.form.login.tab.main'],
    },
    title: locales['access_management.app.form.sign_up.title'],
    subtitle: locales['access_management.app.form.sign_up.subtitle'],
    label: {
      firstName: locales['access_management.app.form.sign_up.first_name'],
      lastName: locales['access_management.app.form.sign_up.last_name'],
      email: locales['access_management.app.form.sign_up.email'],
      company: locales['access_management.app.form.sign_up.company'],
      companyUrl: locales['access_management.app.form.sign_up.company_url'],
      password: locales['access_management.app.form.sign_up.password'],
    },
    checkbox: {
      agree: reactStringReplace(
        locales['access_management.app.form.sign_up.checkbox.agree_with_link'],
        '{link}',
        (_, index) => (
          <Link
            target="_blank"
            href={LINK_TERMS_SERVICE[locale]}
            key={index}
            external
            className={styles['link']}
          >
            {locales['access_management.app.form.sign_up.checkbox.agree_link']}
          </Link>
        ),
      ),
    },
    button: {
      signUp: locales['access_management.app.form.sign_up.button.sign_up'],
    },
    tooltip: {
      password: reactStringReplace(
        locales['access_management.app.form.tooltip.password'],
        '{br}',
        (_, index) => <br key={index} />,
      ),
    },
    aria: {
      password: {
        hide: locales['access_management.app.form.aria.label.hide_password'],
        show: locales['access_management.app.form.aria.label.show_password'],
        tooltip: locales['access_management.app.form.aria.label.password_info'],
      },
    },
    footer: {
      account: {
        message:
          locales['access_management.app.form.sign_up.footer.account.message'],
        link: locales['access_management.app.form.sign_up.footer.account.link'],
      },
      demo: {
        message:
          locales['access_management.app.form.sign_up.footer.demo.message'],
        link: locales['access_management.app.form.sign_up.footer.demo.link'],
      },
    },
    error: {
      field: {
        one: locales['access_management.app.form.sign_up.error.field.one'],
        several:
          locales['access_management.app.form.sign_up.error.field.several'],
      },
      email: {
        premium:
          locales[
            'access_management.app.form.sign_up.error.email.exists.premium'
          ],
        freeTrial:
          locales[
            'access_management.app.form.sign_up.error.email.exists.free_trial'
          ],
        blacklisted:
          locales['access_management.app.form.sign_up.error.email.blacklisted'],
      },
      somethingWentWrong:
        locales[
          'access_management.app.form.sign_up.error.something_went_wrong'
        ],
    },
  };
}
