// import { getNavigatorLocale } from 'core/i18n';
// import { getAppLocales } from './getAppLocales';
import type { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import { EARLY_ADOPTER_60_DAYS } from 'projects/app/AppSignUp/constants';

export function getAppSignUpRightSectionTranslations(): {
  title: {
    part1: string;
    part2: string;
  };
  subtitle: ReactNode;
  items: string[];
} {
  // const locale = getNavigatorLocale();
  // const locales = getAppLocales(locale);
  const amount = window.location.href.includes(EARLY_ADOPTER_60_DAYS) ? 60 : 30;

  return {
    title: {
      part1: 'Get started with',
      part2: 'Kameleoon’s Feature Experimentation',
    },
    subtitle: reactStringReplace(
      'No-risk, {amount}-day free trial',
      '{amount}',
      () => amount,
    ),
    items: [
      'Unlimited feature flags',
      'Multi-environment control',
      'Unlimited rollouts and feature experiments',
      'Custom data for audience targeting',
      'Plug-and-play integrations to analytics tools',
      'Advanced in-app reporting capabilities',
      'Experimentation guardrails for data accuracy',
    ],
  };
}
