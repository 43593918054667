import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormKey, Selector, Errors, SearchParam } from 'core/constants';
import { getInput } from 'core/helpers';
import { Page, paths } from 'core/routes';
import type { LoginResponse as KameleoonLoginResponse } from './login';
import { login as kameleoonLogin } from './login';
import { applyTokens } from './applyTokens';
import { loginEnroll } from './loginEnroll';

const MAX_RETRY_COUNT = 1;

type Params = {
  redirectUri: string;
  callback: () => void;
};

type HookResult = {
  loading: boolean;
  login: (params: LoginParams) => Promise<{ error: Errors | null }>;
};

type LoginParams = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export function useLogin({ redirectUri, callback }: Params): HookResult {
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const loginHandler = useCallback(
    async (
      params: LoginParams,
      retryCount = 0,
    ): Promise<KameleoonLoginResponse> => {
      const response = await kameleoonLogin(
        {
          [FormKey.USERNAME]: String(params.username).trim(),
          [FormKey.PASSWORD]: params.password,
          [FormKey.CSRF_TOKEN]: getInput(Selector.CSRF_TOKEN).value,
          [FormKey.CLIENT_ID]: getInput(Selector.CLIENT_ID).value,
        },
        redirectUri,
      );

      if (response.csrfToken) {
        getInput(Selector.CSRF_TOKEN).value = response.csrfToken;
      }

      if (
        retryCount < MAX_RETRY_COUNT &&
        response.error === Errors.SESSION_EXPIRED
      ) {
        return loginHandler(params, retryCount + 1);
      }

      return response;
    },
    [redirectUri],
  );

  const loginEnrollHandler = useCallback(
    async (
      params: LoginParams,
      { csrfToken, factorId, sharedSecret },
      retryCount = 0,
    ): Promise<Omit<KameleoonLoginResponse, 'factorId' | 'sharedSecret'>> => {
      const response = await loginEnroll(
        {
          [FormKey.USERNAME]: String(params.username).trim(),
          [FormKey.SHARED_SECRET]: sharedSecret,
          [FormKey.CSRF_TOKEN]: csrfToken,
          [FormKey.FACTOR_ID]: factorId,
        },
        redirectUri,
      );

      if (response.csrfToken) {
        getInput(Selector.CSRF_TOKEN).value = response.csrfToken;
      }

      if (
        retryCount < MAX_RETRY_COUNT &&
        response.error === Errors.SESSION_EXPIRED
      ) {
        return loginHandler(params, retryCount + 1);
      }

      return response;
    },
    [loginHandler, redirectUri],
  );

  const getCode = useCallback(
    async (params: LoginParams) => {
      try {
        const response = await loginHandler(params);

        if (response.factorId && !response.sharedSecret) {
          push(
            `${paths[Page.APP_LOGIN_MFA_VERIFY]}?${SearchParam.FACTOR_ID}=${
              response.factorId
            }&${SearchParam.EMAIL}=${encodeURIComponent(params.username)}`,
          );

          return {
            code: null,
            error: null,
          };
        }

        if (response.factorId && response.sharedSecret) {
          const responseEnroll = await loginEnrollHandler(params, response);

          return {
            code: responseEnroll.code,
            error: responseEnroll.error,
          };
        }

        return {
          code: response.code,
          error: response.error,
        };
      } catch (error) {
        throw new Error(JSON.stringify(error));
      }
    },
    [loginEnrollHandler, loginHandler, push],
  );

  const login = useCallback(
    async (params: LoginParams) => {
      setLoading(true);

      try {
        const { code, error } = await getCode(params);

        if (code) {
          const applied = await applyTokens({
            clientId: getInput(Selector.CLIENT_ID).value,
            code,
            rememberMe: params.rememberMe,
            redirectUri,
          });

          if (applied) {
            callback();
          } else {
            throw new Error('Tokens were not applied!');
          }
        }

        if (error) {
          setLoading(false);
        }

        return {
          error,
        };
      } catch (error) {
        setLoading(false);
        throw new Error(JSON.stringify(error));
      }
    },
    [redirectUri, getCode, callback],
  );

  return {
    loading,
    login,
  };
}
