import { Errors } from 'core/constants';
import { REST_URL } from 'core/env';

const UNAUTHORIZED = 401;
const INTERNAL_SERVER_ERROR = 500;

function getError(response: Response): Errors | null {
  switch (response.status) {
    case UNAUTHORIZED:
    case INTERNAL_SERVER_ERROR:
      return Errors.UNEXPECTED;
    default:
      return null;
  }
}

export type ResetPasswordParams = {
  token: string;
  accountId: string;
  password: string;
  passwordConfirm: string;
};

export async function resetPassword({
  token,
  accountId,
  password,
  passwordConfirm,
}: ResetPasswordParams): Promise<{ error: Errors | null }> {
  try {
    const response = await fetch(`${REST_URL}/accounts/${accountId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password,
        passwordConfirm,
      }),
    });

    return {
      error: getError(response),
    };
  } catch (error) {
    throw new Error(JSON.stringify(error));
  }
}
