import type { ReactNode, FormEvent } from 'react';
import { memo } from 'react';
import classNames from 'classnames';
import styles from './Form.module.scss';

type Props = {
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  children?: ReactNode;
  className?: string;
};

function Form({ children, onSubmit, className }: Props): JSX.Element {
  return (
    <form className={classNames(styles['form'], className)} onSubmit={onSubmit}>
      {children}
    </form>
  );
}

export default memo(Form);
